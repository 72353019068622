import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { RootNavigation } from '@/routes/RootNavigation';

import { Team } from '@/app/team/Team';
import { TeamMembers } from '@/app/team/TeamMembers';
import { Team_Properties } from '@/app/team/Team_Properties';
import { Manage } from '@/app/property/Manage';
import { Payments } from '@/app/property/Payments';
import { Documents } from '@/app/property/Documents';
import { Settings } from '@/app/property/Settings';
import { Help } from '@/app/property/Help';
import { Account } from '@/app/account/Account';
import { TeamNavigation } from '@/app-modules/TeamNavigation';
import { PropertyNavigation } from '@/app-modules/PropertyNavigation';
import { Overview } from '@/app/property/Overview';

const router = createBrowserRouter([
	{
		path: '',
		element: <RootNavigation />,
		children: [
			{
				path: '',
				element: <Navigate to='/account' replace />,
			},
			{
				path: 'account',
				element: <Account />,
			},
			{
				path: ':teamId',
				element: <TeamNavigation />,
				children: [
					{
						path: '',
						element: <Team />,
					},
					{
						path: 'members',
						element: <TeamMembers />,
					},
					{
						path: 'properties',
						element: <Team_Properties />,
					},
				],
			},
			{
				path: ':teamId/:propertyId',
				element: <PropertyNavigation />,
				children: [
					{
						path: '',
						element: <Overview />,
					},
					{
						path: 'manage',
						element: <Manage />,
					},
					{
						path: 'payments',
						element: <Payments />,
					},
					{
						path: 'documents',
						element: <Documents />,
					},
					{
						path: 'settings',
						element: <Settings />,
					},
					{
						path: 'help',
						element: <Help />,
					},
				],
			},
		],
	},
]);

export const RoutesCore = () => <RouterProvider router={router} />;
