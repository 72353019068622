import { z } from 'zod';

export const Property_UnitSchema = z.object({
	label: z.string(),
	type: z.union([z.literal('string'), z.literal('number'), z.literal('boolean'), z.array(z.string())]),
	position: z.number(),
	placeholder: z.string(),
	required: z.boolean(),
	hidden: z.boolean(),
	icon: z.string(),
});

export const Property = z.object({
	property_id: z.string(),
	team_id: z.string(),
	name: z.string(),
	year_built: z.number(),
	location: z.object({
		country: z.string(),
		region: z.string(),
		city: z.string(),
		address: z.string(),
		postcode: z.string(),
		latitude: z.number(),
		longitude: z.number(),
	}),
	unit_schema: z.array(Property_UnitSchema),
});
