import { memo } from 'react';
import { TableRow } from '@/styles/ui-components/table';
import { M_ManageTableCell } from '@/app-modules/m-manage/m-manage-tableCell';
import { Unit } from '@/lib/types/unit.types';
import { z } from 'zod';
import { Property_UnitSchema } from '@/lib/types';

interface M_ManageTableRow_Props {
	rowIndex: number;
	unit: z.infer<typeof Unit>;
	unitSchema: z.infer<typeof Property_UnitSchema>[];
	onChange: (unit: z.infer<typeof Unit>) => void;
	onSelectColumn: (columnIndex: number) => void;
}

const M_ManageTableRow = ({ rowIndex, unit, unitSchema, onChange, onSelectColumn }: M_ManageTableRow_Props) => {
	return (
		<TableRow key={unit.unit_id} className='text-xs text-color-grayscale-medium font-normal whitespace-nowrap hover:bg-color-background-medium'>
			{unitSchema
				.filter((schema: z.infer<typeof Property_UnitSchema>) => !schema.hidden)
				.sort((a: z.infer<typeof Property_UnitSchema>, b: z.infer<typeof Property_UnitSchema>) => a.position - b.position)
				.map((schema: z.infer<typeof Property_UnitSchema>, index: number) => (
					<M_ManageTableCell
						id={`row-${rowIndex}-column-${index + 1}`}
						key={schema.label}
						unit={unit}
						unitSchema={schema}
						onChange={(value: string | number | boolean) =>
							onChange(Unit.parse({ ...unit, unit_data: { ...unit.unit_data, [schema.label]: value } }))
						}
						onSelect={() => onSelectColumn(index + 1)}
					/>
				))}
		</TableRow>
	);
};

export const M_ManageTableRow_Memoized = memo(M_ManageTableRow, (prevProps, nextProps) => {
	return prevProps.unit.unit_data === nextProps.unit.unit_data;
});
