import './styles/globals.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { App } from './app.tsx';
import { TooltipProvider } from './styles/ui-components/tooltip.tsx';
import { Toaster } from 'sonner';
import { Provider } from 'react-redux';
import { store } from './lib/redux/store.ts';
import { SpeedInsights } from '@vercel/speed-insights/react';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://91c21010ae3d69031f9f8cae4fec7cdd@o4508162003763200.ingest.us.sentry.io/4508162005925888',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.browserProfilingIntegration(),
			Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: ['localhost', '*.usedwelling.com'],
		profilesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		environment: 'production',
	});
}
createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<TooltipProvider>
			<Provider store={store}>
				<App />
			</Provider>
		</TooltipProvider>
		<Toaster />
		<SpeedInsights />
	</StrictMode>
);
