import { HTMLAttributes } from 'react';
import { cn } from '../lib/utils';

interface RoutesError_Props extends HTMLAttributes<HTMLDivElement> {}

export const RoutesError = ({ className }: RoutesError_Props) => (
	<div className={cn('relative flex items-center justify-center h-screen w-screen', className)}>
		<div className='flex flex-col gap-2 max-w-lg'>
			<div className='text-color-grayscale-dark text-3xl font-normal leading-relaxed'>Oops, something went wrong...</div>
			<div className='text-color-grayscale-medium text-sm font-normal leading-relaxed'>
				Oops! Something unexpected went wrong while loading the app. Don't worry, though - our team has automatically received an error report and is
				already looking into it. We appreciate your patience and apologize for any inconvenience. Please feel free to try again in a little while, or
				contact our support team if the issue persists.
			</div>
		</div>
	</div>
);
