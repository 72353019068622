import { AuthenticationService } from '@/services/authentication.service';
import { RoutesLoading } from '@/routes/Routes.loading';
import { RoutesError } from '@/routes/Routes.error';
import { RoutesAuth } from '@/routes/Routes.auth';
import { RoutesCore } from '@/routes/Routes.core';

export const Routes = () => {
	const { data: isAuthenticated, error, isLoading: isLoadingAuthentication } = AuthenticationService.validateAccessToken();

	if (isLoadingAuthentication) return <RoutesLoading />;
	if (error) return <RoutesError />;

	return isAuthenticated ? <RoutesCore /> : <RoutesAuth />;
};
