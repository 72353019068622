import { z } from 'zod';
import { Team, TeamMembership } from '@/lib/types';
import { store } from '@/lib/redux/store';
import { setTeamsState } from '@/lib/redux/teams.store';
import { BaseService } from './_base.service';

/**
 * @class TeamService
 * Service for interacting with team routes in the core-api
 */
export class TeamService extends BaseService {
	public static list = async (token?: string): Promise<z.infer<typeof Team>[]> => {
		const accessToken = token || (await this.getAccessToken());
		const response = await fetch(this.getCoreUrl('team/list'), {
			method: 'POST',
			body: JSON.stringify({}),
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
		});

		if (!response.ok) throw new Error('Failed to fetch teams');

		try {
			const res = await response.json();
			const teams = res.teams.map((team: any) => Team.parse(team));
			const teamMemberships = res.teamMembership.map((teamMembership: any) => TeamMembership.parse(teamMembership));
			store.dispatch(setTeamsState({ teams, teamMemberships }));
			return teams;
		} catch (e) {
			throw new Error('Failed to fetch teams', { cause: e });
		}
	};

	public static create = async (name: string, domain: string, emails: string[], plan: string, token?: string): Promise<string | undefined> => {
		const accessToken = token || (await this.getAccessToken());
		const response = await fetch(this.getCoreUrl('team/create'), {
			method: 'POST',
			body: JSON.stringify({ name, domain, emails, plan }),
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
		});

		if (!response.ok) return undefined;
		const data = await response.json();
		return data.id;
	};
}
