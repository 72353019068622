import React, { useEffect, useRef, useState } from 'react';
import { CircleDashed, CircleUserRound, Plus, Trash } from 'lucide-react';
import { motion } from 'framer-motion';
import { z } from 'zod';
import { Form, FormProvider, useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem } from '@/styles/ui-components/form';
import { Input } from '@/styles/ui-components/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/styles/ui-components/button';

const formSchema = z.object({
	emails: z.array(z.string().email()),
});
const EmailFormField = ({ form, onRemoveEmail }: { form: UseFormReturn<z.infer<typeof formSchema>>; onRemoveEmail: (emails: string[]) => void }) => {
	const { fields, append, remove } = useFieldArray({
		name: 'emails',
	});

	useEffect(() => {
		if (fields.length === 0) append('');
	}, [fields]);

	return (
		<div className='flex flex-col items-center gap-2 w-full'>
			{fields.map((field, index) => (
				<FormField
					key={field.id}
					control={form.control}
					name={`emails.${index}`}
					render={({ field }) => (
						<FormItem className='flex flex-row items-center gap-2 w-full space-y-0'>
							<FormControl>
								<Input className='flex-1 w-full' type='email' placeholder='john.doe@example.com' {...field} required />
							</FormControl>

							<Button
								variant='outline'
								size='icon'
								type='button'
								className='mt-0'
								onClick={() => {
									remove(index);
									onRemoveEmail(form.getValues('emails'));
								}}
							>
								<Trash strokeWidth={1.5} className='h-4 w-4 text-color-background-placeholder' />
							</Button>
						</FormItem>
					)}
				/>
			))}

			<Button variant='outline' size='regular' className='mt-2 mr-auto' type='button' onClick={() => append('')}>
				<Plus strokeWidth={1.5} className='h-4 w-4 text-color-background-placeholder ' />
				Add a member
			</Button>
		</div>
	);
};

interface M_OnboardingTeam_Members_Props extends React.HTMLAttributes<HTMLDivElement> {
	emails: string[];
	onChangeEmails: (emails: string[]) => void;
}

export const M_OnboardingTeam_Members = ({ emails, onChangeEmails }: M_OnboardingTeam_Members_Props) => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			emails: emails,
		},
	});

	const handleChange = (values: z.infer<typeof formSchema>) => {
		onChangeEmails(values.emails);
	};

	const contentRef = useRef<HTMLDivElement>(null);
	const [renderHeader, setRenderHeader] = useState<boolean>(false);
	useEffect(() => {
		contentRef.current?.addEventListener('scroll', () => {
			if (contentRef.current?.scrollTop && contentRef.current?.scrollTop > 150) setRenderHeader(true);
			else setRenderHeader(false);
		});
		return () => {
			contentRef.current?.removeEventListener('scroll', () => {});
		};
	}, []);

	return (
		<div className='relative flex flex-col overflow-y-auto' ref={contentRef}>
			{renderHeader && (
				<motion.div
					className='fixed top-0 w-full z-10 bg-color-background-light border-b border-color-background-dark p-5'
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.15 }}
				>
					<div className='text-xs font-normal text-color-grayscale-medium'>Invite your Team</div>
				</motion.div>
			)}

			<div className='relative flex items-center justify-center bg-color-background-medium border border-color-background-dark min-h-28 h-28 overflow-clip'>
				<motion.div
					className='z-20 p-3 bg-color-background-light border border-color-background-dark rounded-full w-fit flex flex-col gap-2'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				>
					<CircleUserRound strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
				</motion.div>

				<motion.div
					className='absolute z-10 h-[42px] w-[42px] bg-[radial-gradient(circle,_var(--color-background-dark)_0%,_transparent_100%)] border border-color-background-dark rounded-full animate-ping-slow'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				/>

				<motion.div
					className='absolute z-10 w-[200px] h-[200px] border border-color-background-dark border-dashed rounded-full'
					initial={{ opacity: 0, rotate: 0 }}
					animate={{
						opacity: 1,
						rotate: 360,
					}}
					transition={{
						opacity: { delay: 0.6, duration: 1.5 },
						rotate: {
							duration: 240,
							repeat: Infinity,
							ease: 'linear',
						},
					}}
				>
					{Array(10)
						.fill(null)
						.map((_, index) => (
							<div
								key={index}
								style={{
									position: 'absolute',
									left: '50%',
									top: '50%',
									transform: `translate(-50%, -50%) rotate(${index * 36}deg) translateY(-100px)`,
								}}
							>
								<motion.div
									className='p-2 bg-color-background-light border border-color-background-dark rounded-full'
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ elay: 0.6, duration: 1.5 }}
								>
									<CircleDashed strokeWidth={1} className='h-4 w-4 text-color-background-placeholder' />
								</motion.div>
							</div>
						))}
				</motion.div>
			</div>

			<div className='flex flex-col gap-2 px-4 py-6'>
				<motion.div
					className='text-xl text-color-grayscale-dark font-normal'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}
				>
					Invite your Colleagues
				</motion.div>

				<motion.div
					className='text-xs text-color-grayscale-medium font-normal leading-relaxed'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.1, ease: 'easeInOut' }}
				>
					Invite team members below and they'll receive an email to join Dwelling. If domain access is enabled, members can join automatically,
					however, they will only get an email if added here.
				</motion.div>

				<motion.div
					className='text-xs text-color-grayscale-medium font-normal leading-relaxed mt-2 flex flex-col gap-2'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.2, ease: 'easeInOut' }}
				>
					<FormProvider {...form}>
						<Form {...form}>
							<form onChange={form.handleSubmit(handleChange)} className='flex flex-row gap-2'>
								<EmailFormField form={form} onRemoveEmail={(emails: string[]) => onChangeEmails(emails)} />
							</form>
						</Form>
					</FormProvider>
				</motion.div>
			</div>
		</div>
	);
};
