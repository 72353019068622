import { createSlice } from '@reduxjs/toolkit';

interface MapboxState {
	token: string | undefined;
}

const initialState: MapboxState = {
	token: undefined,
};

export const mapboxSlice = createSlice({
	name: 'mapbox',
	initialState,
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload ?? undefined;
		},
		clearToken: (state) => {
			state.token = initialState.token;
		},
	},
});

export const { setToken, clearToken } = mapboxSlice.actions;
