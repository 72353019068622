import { HTMLAttributes, useState } from 'react';
import { z } from 'zod';
import { Globe } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
import { Input } from '@/styles/ui-components/input';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/styles/ui-components/form';
import { Form, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMapboxAutofill, useSearchSession } from '@mapbox/search-js-react';

const mapboxAccessToken = 'pk.eyJ1IjoibWF0dGRob2JicyIsImEiOiJjbTJub2llem8wN3UyMnBxNWN2aDMxZmx5In0.zr7HuS6Exn3F3MjpukQSGQ';

const formSchema = z.object({
	address: z.string(),
	city: z.string(),
	state: z.string(),
	zipCode: z.string(),
	country: z.string(),
});

const AddressFormField = ({
	form,
	className,
	onFocus,
	onUnfocus,
}: {
	form: UseFormReturn<z.infer<typeof formSchema>>;
	className?: string;
	onFocus: () => void;
	onUnfocus: () => void;
}) => (
	<FormField
		control={form.control}
		name='address'
		render={({ field }) => (
			<FormItem className={className}>
				<FormLabel>Address</FormLabel>
				<FormControl>
					<Input type='text' {...field} autoComplete='off' required onFocus={() => onFocus()} onBlur={() => onUnfocus()} />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const CityFormField = ({ form, className }: { form: UseFormReturn<z.infer<typeof formSchema>>; className?: string }) => (
	<FormField
		control={form.control}
		name='city'
		render={({ field }) => (
			<FormItem className={className}>
				<FormLabel>City</FormLabel>
				<FormControl>
					<Input type='text' {...field} autoComplete='off' required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const RegionFormField = ({ form, className }: { form: UseFormReturn<z.infer<typeof formSchema>>; className?: string }) => (
	<FormField
		control={form.control}
		name='state'
		render={({ field }) => (
			<FormItem className={className}>
				<FormLabel>State / Province / Region</FormLabel>
				<FormControl>
					<Input type='text' {...field} autoComplete='off' required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const CountryFormField = ({ form, className }: { form: UseFormReturn<z.infer<typeof formSchema>>; className?: string }) => (
	<FormField
		control={form.control}
		name='country'
		render={({ field }) => (
			<FormItem className={className}>
				<FormLabel>Country</FormLabel>
				<FormControl>
					<Input type='text' {...field} autoComplete='off' required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const ZipCodeFormField = ({ form, className }: { form: UseFormReturn<z.infer<typeof formSchema>>; className?: string }) => (
	<FormField
		control={form.control}
		name='zipCode'
		render={({ field }) => (
			<FormItem className={className}>
				<FormLabel>Postcode</FormLabel>
				<FormControl>
					<Input type='text' {...field} autoComplete='off' required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

interface M_OnboardingProperty_Address_Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
	country: string;
	state: string;
	city: string;
	address: string;
	zip: string;
	setCountry: (country: string) => void;
	setState: (state: string) => void;
	setCity: (city: string) => void;
	setAddress: (address: string) => void;
	setZip: (zip: string) => void;
}

export const M_OnboardingProperty_Address = ({
	country,
	state,
	city,
	address,
	zip,
	setCountry,
	setState,
	setCity,
	setAddress,
	setZip,
}: M_OnboardingProperty_Address_Props) => {
	const autofill = useMapboxAutofill({ accessToken: mapboxAccessToken });
	const session = useSearchSession(autofill);

	const [addressFocus, setAddressFocus] = useState(false);
	const [suggestions, setSuggestions] = useState<any[]>([]);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			country: country,
			state: state,
			city: city,
			address: address,
			zipCode: zip,
		},
	});

	const onChange = async (values: z.infer<typeof formSchema>) => {
		setCountry(values.country);
		setState(values.state);
		setCity(values.city);
		setAddress(values.address);
		setZip(values.zipCode);

		const result = await autofill.suggest(values.address, {
			country: 'us',
			sessionToken: session.sessionToken,
		});
		setSuggestions(result.suggestions);

		console.log(result.suggestions);
	};

	const handleSelection = (suggestion: any) => {
		form.setValue('country', suggestion.country);
		form.setValue('state', suggestion.region);
		form.setValue('city', suggestion.place);
		form.setValue('address', suggestion.address_line1);
		form.setValue('zipCode', suggestion.postcode);

		setCountry(suggestion.country);
		setState(suggestion.region);
		setCity(suggestion.place);
		setAddress(suggestion.address_line1);
		setZip(suggestion.postcode);

		setSuggestions([]);
	};

	return (
		<div className='relative overflow-clip flex flex-col'>
			<div className='relative flex items-center justify-center bg-color-background-medium border border-color-background-dark h-28 overflow-clip'>
				<motion.div
					className='z-20 p-3 bg-color-background-light border border-color-background-dark rounded-full w-fit flex flex-col gap-2'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				>
					<Globe strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
				</motion.div>

				<motion.div
					className='absolute z-10 h-[42px] w-[42px] bg-[radial-gradient(circle,_var(--color-background-dark)_0%,_transparent_100%)] border border-color-background-dark rounded-full animate-ping-slow'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				/>

				<motion.div
					className='z-0 absolute'
					initial={{ opacity: 0, scale: 0.9 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 2, delay: 0 }}
				>
					<img src='/map.svg' alt='Map' className='h-full opacity-[0.03]' />
				</motion.div>
			</div>

			<div className='flex flex-col gap-2 px-4 py-6'>
				<motion.div
					className='text-xl text-color-grayscale-dark font-normal'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}
				>
					Property Address
				</motion.div>

				<motion.div
					className='text-xs text-color-grayscale-medium font-normal leading-relaxed'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.1, ease: 'easeInOut' }}
				>
					Dwelling is currently supported across Canada and the United States. Enter your building's address below.
				</motion.div>

				<motion.div
					className='text-xs text-color-grayscale-medium font-normal leading-relaxed mt-2'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.2, ease: 'easeInOut' }}
				>
					<FormProvider {...form}>
						<Form {...form}>
							<form onChange={form.handleSubmit(onChange)} className='flex flex-col gap-4 w-full'>
								<div className='relative flex flex-col gap-2'>
									<AddressFormField form={form} onFocus={() => setAddressFocus(true)} onUnfocus={() => setAddressFocus(false)} />
									<AnimatePresence>
										{suggestions.length > 0 && addressFocus && (
											<motion.div
												className='absolute top-16 left-0 w-full max-h-36 overflow-y-scroll flex flex-col bg-color-background-light border border-color-background-dark shadow-lg rounded-lg p-1'
												initial={{ opacity: 0, y: 5 }}
												animate={{ opacity: 1, y: 0 }}
												exit={{ opacity: 0, y: 5 }}
												transition={{ duration: 0.3, ease: 'easeInOut' }}
											>
												{suggestions.map((suggestion) => (
													<div
														key={suggestion.id}
														className='p-2 text-color-grayscale-medium whitespace-nowrap overflow-x-clip overflow-ellipsis cursor-pointer hover:bg-color-background-medium transition-all'
														onClick={() => handleSelection(suggestion)}
													>
														{suggestion.full_address}
													</div>
												))}
											</motion.div>
										)}
									</AnimatePresence>
								</div>
								<CityFormField form={form} />
								<div className='flex flex-row gap-4 w-full'>
									<RegionFormField form={form} className='w-full' />
									<ZipCodeFormField form={form} className='w-full' />
								</div>
								<CountryFormField form={form} />
							</form>
						</Form>
					</FormProvider>
				</motion.div>
			</div>
		</div>
	);
};
