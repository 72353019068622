import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { Property } from '@/lib/types';
import { Button } from '@/styles/ui-components/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/styles/ui-components/popover';
import { ChevronsUpDown, Loader2, Plus } from 'lucide-react';
import { Separator } from '@/styles/ui-components/separator';
import { M_OnboardingProperty } from '@/app-modules/m-onboardingProperty/m-onboardingProperty';
import { UnitService } from '@/services/unit.service';
import { clearUnits } from '@/lib/redux/units.store';
import { Input } from '@/styles/ui-components/input';
import { z } from 'zod';

export const RootNavigation_Property = () => {
	const navigate = useNavigate();
	const { teamId, propertyId } = useParams();
	const dispatch = useAppDispatch();
	const properties = useAppSelector((state) => state.properties);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const closePopover = () => setIsPopoverOpen(false);

	const [search, setSearch] = useState<string>('');
	const [property, setProperty] = useState<z.infer<typeof Property> | undefined>(undefined);
	useEffect(() => {
		if (properties.properties && properties.properties.length > 0)
			setProperty(properties.properties?.find((property: z.infer<typeof Property>) => property.property_id === propertyId));
	}, [properties.properties, propertyId]);

	useEffect(() => {
		if (teamId && propertyId) {
			dispatch(clearUnits());
			UnitService.list(teamId, propertyId);
		}
	}, [teamId, propertyId]);

	return (
		<div className='flex flex-row items-center'>
			{/* <Avatar className={cn('border-color-background-light bg-color-background-light mr-2', propertyId ? 'opacity-100' : 'opacity-50')}>
				<AvatarFallback className='text-color-brand-medium'>
					{properties.properties !== undefined && properties.properties.length > 0 && property && property.name[0].toUpperCase()}
					{properties.properties !== undefined && property === undefined && '?'}
					{properties.properties === undefined && <Loader2 strokeWidth={1.5} className='h-3 w-3 text-color-brand-medium animate-spin' />}
				</AvatarFallback>
			</Avatar> */}

			<div className='flex flex-row'>
				<Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
					<PopoverTrigger asChild>
						{property ? (
							<Button variant='ghost' size='small' className='flex flex-row gap-2 items-center text-xs font-normal'>
								<span>{property.name}</span>
								<span className='opacity-50'>
									{property.location.address}, {property.location.city}, {property.location.region}, {property.location.country}
								</span>
								<ChevronsUpDown strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
							</Button>
						) : (
							<Button variant='ghost' size='small' className='flex flex-row gap-2 items-center text-xs font-normal'>
								Select a property
								<ChevronsUpDown strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
							</Button>
						)}
					</PopoverTrigger>
					<PopoverContent className='w-fit'>
						{properties.properties === undefined && (
							<div className='text-xs text-color-grayscale-medium leading-relaxed p-4 max-w-xs'>
								<Loader2 strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light animate-spin' />
							</div>
						)}
						{properties.properties && properties.properties.length === 0 && (
							<div className='text-xs text-color-grayscale-medium leading-relaxed p-3 max-w-xs'>
								There are no properties associated with this team. Add a property to get started.
							</div>
						)}
						{properties.properties && properties.properties.length > 0 && (
							<>
								<Input placeholder='Search your properties...' className='m-2' value={search} onChange={(e) => setSearch(e.target.value)} />
								<Separator orientation='horizontal' />
								<div className='p-1 flex flex-col'>
									{properties.properties
										.filter((p) => p.name.toLowerCase().includes(search.toLowerCase()))
										.map((property: z.infer<typeof Property>) => (
											<Button
												key={property.property_id}
												variant='ghost'
												size='regular'
												className='flex flex-row gap-2'
												onClick={() => {
													closePopover();
													navigate(`/${teamId}/${property.property_id}`);
												}}
											>
												<span className='mr-auto whitespace-nowrap'>{property.name}</span>
												<span className='text-color-background-placeholder overflow-ellipsis line-clamp-1 break-all'>{`${property.location.address}, ${property.location.city}, ${property.location.region}, ${property.location.country}`}</span>
											</Button>
										))}
								</div>
							</>
						)}
					</PopoverContent>
				</Popover>

				<M_OnboardingProperty>
					<Button variant='ghost' size='small'>
						<Plus strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light ml-auto' />
					</Button>
				</M_OnboardingProperty>
			</div>
		</div>
	);
};
