import React, { useState } from 'react';
import { Circle, CircleCheck, Milestone } from 'lucide-react';
import { motion } from 'framer-motion';

interface M_OnboardingTeam_Plan_Props extends React.HTMLAttributes<HTMLDivElement> {
	plan: 'pro' | 'basic';
	onChangePlan: (plan: 'pro' | 'basic') => void;
}

export const M_OnboardingTeam_Plan = ({ plan, onChangePlan }: M_OnboardingTeam_Plan_Props) => {
	const [selectedPlan, setSelectedPlan] = useState<'pro' | 'basic'>(plan);

	const plans = [
		{
			name: 'Pro',
			price: '$2.49 / Unit / Month',
			description: 'Unlock the full potential of Dwelling with unlimited features, advanced analytics, and priority support.',
			type: 'pro' as const,
		},
		{
			name: 'Basic',
			price: '$1.49 / Unit / Month',
			description: 'Get started with essential property management tools and core Dwelling features.',
			type: 'basic' as const,
		},
	];

	return (
		<div className='relative overflow-clip flex flex-col'>
			<div className='relative flex items-center justify-center bg-color-background-medium border border-color-background-dark h-28 overflow-clip'>
				<motion.div
					className='z-20 p-3 bg-color-background-light border border-color-background-dark rounded-full w-fit flex flex-col gap-2'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				>
					<Milestone strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
				</motion.div>

				<motion.div
					className='absolute z-10 h-[42px] w-[42px] bg-[radial-gradient(circle,_var(--color-background-dark)_0%,_transparent_100%)] border border-color-background-dark rounded-full animate-ping-slow'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				/>
			</div>

			<div className='flex flex-col gap-2 px-4 py-6'>
				<motion.div
					className='text-xl text-color-grayscale-dark font-normal'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}
				>
					Select a Plan
				</motion.div>

				<motion.div
					className='text-xs text-color-grayscale-medium font-normal leading-relaxed'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.1, ease: 'easeInOut' }}
				>
					Choose the plan that best fits your needs. You can always change your plan later.
				</motion.div>

				{plans.map((plan, index) => (
					<motion.div
						key={plan.type}
						className={`flex flex-col gap-2 border border-color-background-dark bg-color-background-light rounded p-4 mt-2 cursor-pointer ${
							selectedPlan === plan.type && 'border-color-brand-light outline outline-2 outline-color-brand-extra-light'
						}`}
						initial={{ y: '5px', opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ duration: 0.3, delay: 0.2 + index * 0.1, ease: 'easeInOut' }}
						onClick={() => {
							setSelectedPlan(plan.type);
							onChangePlan(plan.type);
						}}
					>
						<div className='flex flex-row gap-2 items-center'>
							{selectedPlan === plan.type ? (
								<CircleCheck strokeWidth={1.5} className='h-4 w-4 text-color-brand-medium' />
							) : (
								<Circle strokeWidth={1.5} className='h-4 w-4 text-color-background-placeholder' />
							)}
							<div className='text-xs text-color-grayscale-dark font-normal'>{plan.name}</div>
							<div className='text-xs text-color-grayscale-medium font-normal ml-auto'>{plan.price}</div>
						</div>
						<div className='text-xs text-color-grayscale-light font-normal leading-relaxed'>{plan.description}</div>
					</motion.div>
				))}
			</div>
		</div>
	);
};
