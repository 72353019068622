import { createSlice } from '@reduxjs/toolkit';

interface UserState {
	id: string | undefined;
	email: string | undefined;
	givenName: string | undefined;
	familyName: string | undefined;
	avatarUrl: string | undefined;
	accessToken: string | undefined;
}

const initialState: UserState = {
	id: undefined,
	email: undefined,
	givenName: undefined,
	familyName: undefined,
	avatarUrl: undefined,
	accessToken: undefined,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.id = action.payload.id ?? undefined;
			state.givenName = action.payload.givenName ?? undefined;
			state.familyName = action.payload.familyName ?? undefined;
			state.email = action.payload.email ?? undefined;
			state.avatarUrl = action.payload.avatarUrl ?? undefined;
			state.accessToken = action.payload.accessToken ?? undefined;
		},
		clearState: (state) => {
			state.id = initialState.id;
			state.email = initialState.email;
			state.givenName = initialState.givenName;
			state.familyName = initialState.familyName;
			state.avatarUrl = initialState.avatarUrl;
			state.accessToken = initialState.accessToken;
		},
	},
});

export const { setUser, clearState } = userSlice.actions;
