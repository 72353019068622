import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTrigger } from '@/styles/ui-components/dialog';
import { Button } from '@/styles/ui-components/button';
import { M_OnboardingTeam_Intro } from './m-onboardingTeam-Intro';
import { M_OnboardingTeam_Members } from './m-onboardingTeam-Members';
import { M_OnboardingTeam_Complete } from './m-onboardingTeam-Complete';
import { M_OnboardingTeam_Plan } from './m-onboardingTeam-Plan';
import { Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { TeamService } from '@/services/team.service';

interface M_OnboardingTeam_Props extends React.HTMLAttributes<HTMLDivElement> {}

export const M_OnboardingTeam = ({ children }: M_OnboardingTeam_Props) => {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);

	const [name, setName] = useState<string>('');
	const [domain, setDomain] = useState<string | undefined>('');
	const [emails, setEmails] = useState<string[]>([]);
	const [plan, setPlan] = useState<'pro' | 'basic'>('pro');
	const [formRenderKey, setFormRenderKey] = useState<number>(() => Date.now());
	const [step, setStep] = useState<number>(0);

	const steps = [
		{
			Element: () => (
				<M_OnboardingTeam_Intro
					name={name}
					domain={domain}
					onChangeName={(name: string) => setName(name)}
					onChangeDomainAllow={(domain: string | undefined) => setDomain(domain)}
				/>
			),
			isValid: (): boolean => name.length > 0,
		},
		{
			Element: () => <M_OnboardingTeam_Members emails={emails} onChangeEmails={(emails: string[]) => setEmails(emails)} />,
			isValid: (): boolean => true,
		},
		{
			Element: () => <M_OnboardingTeam_Plan plan={plan} onChangePlan={(plan: 'pro' | 'basic') => setPlan(plan)} />,
			isValid: (): boolean => true,
		},
		{
			Element: () => <M_OnboardingTeam_Complete name={name} domain={domain} emails={emails} plan={plan} />,
			isValid: (): boolean => true,
		},
	];

	const resetForm = () => {
		setName('');
		setDomain('');
		setEmails([]);
		setPlan('pro');
		setStep(0);
		setFormRenderKey(Date.now());
	};

	const createTeam = async () => {
		setIsLoading(true);
		const id = await TeamService.create(name, domain || '', emails, plan);
		await TeamService.list();
		if (id !== undefined) {
			setOpen(false);
			resetForm();
			navigate(`/${id}`);
		}
		setIsLoading(false);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent className='flex flex-col max-w-sm h-[600px] overflow-clip'>
				<div key={formRenderKey} className='flex flex-col flex-1 h-full w-full'>
					{steps[step].Element()}

					<div className='flex flex-row gap-2 p-4 border-t border-color-background-dark bg-color-background-light w-full mt-auto'>
						{/* If onboarding form is dirty, allow user to reset it. We do not reset when the modal is closed to prevent accidental resets. */}
						{(name !== '' || domain !== '' || emails.length > 0 || plan !== 'pro') && (
							<Button variant='ghost' size='small' className='mr-2' onClick={resetForm}>
								Reset
							</Button>
						)}

						<div className='flex flex-row gap-2 items-center mr-auto'>
							{steps.map((_, index) => (
								<div
									key={index}
									className={`cursor-pointer w-2 h-2 rounded-full transition-all ${
										index === step ? 'bg-color-brand-medium' : 'bg-color-background-dark'
									}`}
								/>
							))}
						</div>

						{step > 0 && (
							<Button variant='outline' size='regular' onClick={() => setStep(step - 1)}>
								Back
							</Button>
						)}

						{step < steps.length - 1 ? (
							<Button variant='brand' size='regular' onClick={() => setStep(step + 1)} disabled={!steps[step].isValid()}>
								Next
							</Button>
						) : (
							<Button variant='brand' size='regular' disabled={isLoading || !steps[step].isValid()} onClick={createTeam}>
								{isLoading ? <Loader2 className='w-4 h-4 animate-spin' /> : 'Create team'}
							</Button>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};
