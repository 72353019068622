import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Building, LayoutGrid, Users2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { Button } from '@/styles/ui-components/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/styles/ui-components/tooltip';

type NavItem = {
	icon: React.ReactNode;
	tooltip: string;
	link: string;
};

const navItems = (teamId: string): NavItem[] => [
	{ icon: <LayoutGrid strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />, tooltip: 'Overview', link: `/${teamId}` },
	{ icon: <Building strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />, tooltip: 'Properties', link: `/${teamId}/properties` },
	{ icon: <Users2 strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />, tooltip: 'Members', link: `/${teamId}/members` },
];

interface TeamNavigation_Props extends React.HTMLAttributes<HTMLDivElement> {}

export const TeamNavigation = ({}: TeamNavigation_Props) => {
	const { teamId } = useParams();
	const location = useLocation();
	const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
	const [activeIndex, setActiveIndex] = useState<number>(-1);
	const navigate = useNavigate();
	if (teamId === undefined) return <></>;

	useEffect(() => {
		setActiveIndex(navItems(teamId).findIndex((item: NavItem) => location.pathname === item.link));
	}, [location]);

	return (
		<div className='flex flex-row h-full w-full'>
			<div className='p-2 w-[50px] min-w-[50px] max-w-[50px] flex flex-col items-center bg-color-background-light border-r border-color-background-dark relative'>
				{navItems(teamId).map((item, index) => (
					<Tooltip key={index}>
						<TooltipTrigger asChild>
							<Button
								variant='ghost'
								size='icon'
								className='z-10 h-[38px] relative bg-transparent hover:bg-transparent'
								onMouseEnter={() => setHoveredIndex(index)}
								onMouseLeave={() => setHoveredIndex(null)}
								onClick={() => navigate(item.link)}
							>
								{item.icon}
							</Button>
						</TooltipTrigger>
						<TooltipContent side='right' className='ml-2.5'>
							{item.tooltip}
						</TooltipContent>
					</Tooltip>
				))}

				{hoveredIndex !== null && (
					<motion.div
						className='z-0 absolute w-[34px] h-[34px] mt-[2px] bg-color-background-medium rounded'
						initial={{ opacity: 0, scale: 0, y: hoveredIndex * 38 }}
						animate={{
							opacity: 1,
							scale: 1,
							y: hoveredIndex * 38,
						}}
						transition={{ type: 'spring', duration: 0.3, ease: 'easeInOut' }}
					/>
				)}

				{activeIndex !== -1 && (
					<motion.div
						className='z-0 absolute w-[4px] h-[24px] mt-[7px] right-0 bg-color-grayscale-dark rounded-l'
						initial={{ opacity: 0, scale: 0.5, y: activeIndex * 38 }}
						animate={{
							opacity: 1,
							scale: 1,
							y: activeIndex * 38,
						}}
						transition={{ type: 'spring', duration: 0.6, ease: 'easeInOut' }}
					/>
				)}
			</div>

			<div className='flex-1 flex flex-col h-full w-[calc(100%-50px)]'>
				<Outlet />
			</div>
		</div>
	);
};
