import { forwardRef } from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';
import { AnimatePresence, motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';

const buttonVariants = cva(
	'flex flex-row gap-2 items-center justify-center text-xs font-normal disabled:opacity-75 disabled:pointer-events-none transition-all outline-none',
	{
		variants: {
			variant: {
				brand: 'text-color-background-light bg-color-brand-medium hover:bg-color-brand-dark',
				muted: 'text-color-grayscale-medium border border-color-background-medium bg-color-background-medium hover:bg-color-background-dark',
				outline: 'text-color-grayscale-medium border border-color-background-dark bg-color-background-light hover:bg-color-background-medium',
				link: 'text-color-grayscale-medium hover:text-color-grayscale-dark underline-offset-4 hover:underline',
				ghost: 'text-color-grayscale-medium bg-transparent hover:bg-color-background-medium',

				success: 'text-green-50 border border-green-700 bg-green-700 opacity-95 hover:opacity-100 disabled:opacity-75',
				indigo: 'text-indigo-50 border border-indigo-700 bg-indigo-700 opacity-95 hover:opacity-100 disabled:opacity-75',
			},
			size: {
				regular: 'px-4 py-2 rounded',
				small: 'px-2 py-2 rounded',
				icon: 'w-[34px] h-[34px] rounded',
			},
		},
		defaultVariants: {
			variant: 'brand',
			size: 'regular',
		},
	}
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
	loading?: boolean;
	asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ loading, className, variant, size, asChild = false, ...props }, ref) => {
	const Comp = asChild ? Slot : 'button';
	return (
		<Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} onMouseDown={(e) => e.preventDefault()}>
			<AnimatePresence mode='wait'>
				{loading && (
					<motion.div
						className='flex flex-row gap-2'
						initial={{ width: 0 }}
						animate={{ width: 'auto', transition: { delay: 0, duration: 0.15 } }}
						exit={{ width: 0, transition: { delay: 0.05, duration: 0.15 } }}
					>
						<motion.div
							className='flex flex-row gap-2'
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { delay: 0.05, duration: 0.15 } }}
							exit={{ opacity: 0, transition: { delay: 0, duration: 0.15 } }}
						>
							<Loader2 strokeWidth={1.5} className='w-4 h-4 text-color-background-light animate-spin' />
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>

			{props.children}
		</Comp>
	);
});
Button.displayName = 'Button';

export { Button };
