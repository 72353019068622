import { createSlice } from '@reduxjs/toolkit';
import { Property } from '../types';
import { z } from 'zod';

interface PropertiesState {
	properties: z.infer<typeof Property>[] | undefined;
}

const initialState: PropertiesState = {
	properties: undefined,
};

export const propertiesSlice = createSlice({
	name: 'properties',
	initialState,
	reducers: {
		setProperties: (state, action) => {
			state.properties = action.payload ?? undefined;
		},
		clearProperties: (state) => {
			state.properties = initialState.properties;
		},
	},
});

export const { setProperties, clearProperties } = propertiesSlice.actions;
