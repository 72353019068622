import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { UseFormReturn, useForm } from 'react-hook-form';
import { Loader2 } from 'lucide-react';
import { AuthenticationService } from '@/services/authentication.service';
import { Button } from '@/styles/ui-components/button';
import { Input } from '@/styles/ui-components/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/styles/ui-components/form';
import { useNavigate } from 'react-router-dom';
import { AuthWrapper } from '@/app/auth/_AuthWrapper';
import { setUser } from '@/lib/redux/user.store';
import { useAppDispatch } from '@/lib/hooks';

const formSchema = z.object({
	email: z.string(),
});

const EmailFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='email'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Email address</FormLabel>
				<FormControl>
					<Input type='email' {...field} required autoFocus />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

interface ForgotPasswordProps extends React.HTMLAttributes<HTMLDivElement> {}

export const ForgotPassword = ({}: ForgotPasswordProps) => {
	useEffect(() => {
		document.title = 'Dwelling • Forgot Password';
	}, []);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
		},
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setLoading(true);
		const response = await AuthenticationService.sendForgotPassword(values.email);
		if (response.ok) {
			dispatch(setUser({ email: values.email }));
			navigate('/auth/verify-password');
		}
		setLoading(false);
	};

	return (
		<AuthWrapper title='Forgot password.' description='Forgotten your password? No worries, enter your email below and we will send you a reset code.'>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-2'>
					<EmailFormField form={form} />

					<Button variant='brand' className='w-full flex flex-row gap-2 items-center justify-center mt-4' type='submit' disabled={loading}>
						{loading && <Loader2 className='w-4 h-4 animate-spin' />}
						{!loading && 'Send reset code'}
					</Button>
				</form>
			</Form>

			<div className='flex flex-row gap-4 w-full mt-2'>
				<Button variant='ghost' className='flex-1' onClick={() => navigate('/auth/sign-in')}>
					Back to sign in
				</Button>
			</div>
		</AuthWrapper>
	);
};
