import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { UseFormReturn, useForm } from 'react-hook-form';
import { Loader2 } from 'lucide-react';
import { AuthenticationService } from '@/services/authentication.service';
import { Button } from '@/styles/ui-components/button';
import { Input } from '@/styles/ui-components/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/styles/ui-components/form';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/lib/hooks';
import { AuthWrapper } from './_AuthWrapper';

const formSchema = z.object({
	code: z.string(),
});

const CodeFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='code'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Code</FormLabel>
				<FormControl>
					<Input type='password' {...field} required autoFocus />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

interface VerifyAccountProps extends React.HTMLAttributes<HTMLDivElement> {}

export const VerifyAccount = ({}: VerifyAccountProps) => {
	useEffect(() => {
		document.title = 'Dwelling • Verify Account';
	}, []);

	const navigate = useNavigate();
	const user = useAppSelector((state) => state.user);

	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>('');

	useEffect(() => {
		if (!success && !user.email) navigate('/auth/sign-in');
	}, [success]);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			code: '',
		},
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setLoading(true);
		const response = await AuthenticationService.verifyCode(user.email || '', values.code);
		if (response.status === 'success') setSuccess(true);
		else if (response.status === 'error') setError(response.message || '');
		setLoading(false);
	};

	return (
		<AuthWrapper
			title={!success ? 'Verify your account.' : 'Account verified.'}
			description={
				!success
					? `An account verification code has been sent to ${user.email}. Enter the code top verify your account.`
					: `Your account has been verified. You can now sign in to your account.`
			}
		>
			{!success && (
				<>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-2'>
							<CodeFormField form={form} />

							<Button variant='brand' className='w-full flex flex-row gap-2 items-center justify-center mt-4' type='submit' disabled={loading}>
								{loading && <Loader2 className='w-4 h-4 animate-spin' />}
								{!loading && 'Verify account'}
							</Button>

							{error && <div className='mt-2 text-xs text-red-700 leading-relaxed'>{error}</div>}
						</form>
					</Form>
				</>
			)}

			{success && (
				<div className='flex flex-col gap-2 w-full mt-4'>
					<Button variant='brand' className='flex-1' onClick={() => navigate('/auth/sign-in')}>
						Back to Sign In
					</Button>
				</div>
			)}
		</AuthWrapper>
	);
};
