import { Unit } from '@/lib/types/unit.types';
import { KeyboardEvent } from 'react';
import { z } from 'zod';

export type CellPosition = { row: number | null; column: number | null };

export const handleKeyPositionChange = (
	previousPosition: { row: number | null; column: number | null },
	units: z.infer<typeof Unit>[] | undefined,
	e: KeyboardEvent
) => {
	const { row, column } = previousPosition;
	if (row === null || column === null) return previousPosition;
	let newPosition = previousPosition;

	switch (e.key) {
		case 'ArrowUp':
			newPosition = row > 0 ? { row: row - 1, column } : previousPosition;
			break;
		case 'ArrowDown':
			newPosition = row < (units?.length || 1) - 1 ? { row: row + 1, column } : previousPosition;
			break;
		case 'ArrowLeft':
			newPosition = column > 0 ? { row, column: column - 1 } : previousPosition;
			break;
		// case 'ArrowRight':
		// 	newPosition = column < units.unit.length - 1 ? { row, column: column + 1 } : previousPosition;
		// 	break;
		default:
			newPosition = previousPosition;
	}

	const cell = document.getElementById(`row-${newPosition.row}-column-${newPosition.column}`);
	if (cell) cell.focus();
	return newPosition;
};
