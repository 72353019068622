import { HTMLAttributes } from 'react';
import { Loader2 } from 'lucide-react';
import { cn } from '../lib/utils';

interface RoutesLoading_Props extends HTMLAttributes<HTMLDivElement> {}

export const RoutesLoading = ({ className }: RoutesLoading_Props) => (
	<div className={cn('relative flex items-center justify-center h-screen w-screen', className)}>
		<Loader2 strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light animate-spin' />
	</div>
);
