import { store } from '@/lib/redux/store';
import { BaseService } from './_base.service';
import { setToken } from '@/lib/redux/mapbox.store';

/**
 * @class MapboxService
 * Service for handling mapbox functionality. The token retrieved by this serviced should be scoped to the dwelling URLs in the Mapbox tokens UI.
 */
export class MapboxService extends BaseService {
	public static getToken = async (token?: string): Promise<void> => {
		const accessToken = token || (await this.getAccessToken());
		const response = await fetch(this.getCoreUrl('mapbox/get-token'), {
			method: 'POST',
			body: JSON.stringify({ access_token: accessToken }),
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
		});

		if (response.ok) {
			const data = await response.json();
			store.dispatch(setToken(data.mapbox_token));
		}
	};
}
