import { RouterProvider, Navigate, createBrowserRouter } from 'react-router-dom';
import { SignIn } from '@/app/auth/SignIn';
import { SignUp } from '@/app/auth/SignUp';
import { ForgotPassword } from '@/app/auth/ForgotPassword';
import { VerifyAccount } from '@/app/auth/VerifyAccount';
import { VerifyPassword } from '@/app/auth/VerifyPassword';

const router = createBrowserRouter([
	{
		path: '/auth/sign-in',
		element: <SignIn />,
	},
	{
		path: '/auth/sign-up',
		element: <SignUp />,
	},
	{
		path: '/auth/forgot-password',
		element: <ForgotPassword />,
	},
	{
		path: '/auth/verify-account',
		element: <VerifyAccount />,
	},
	{
		path: '/auth/verify-password',
		element: <VerifyPassword />,
	},
	{
		path: '*',
		element: <Navigate to='/auth/sign-in' replace />,
	},
]);

export const RoutesAuth = () => <RouterProvider router={router} />;
