import { TableCell } from '@/styles/ui-components/table';
import { Unit } from '@/lib/types/unit.types';
import { HTMLAttributes } from 'react';
import { Property_UnitSchema } from '@/lib/types';
import { z } from 'zod';

interface M_ManageTableCell_Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
	unit: z.infer<typeof Unit>;
	unitSchema: z.infer<typeof Property_UnitSchema>;
	onChange: (id: string, value: string) => void;
}

export const M_ManageTableCell = ({ id, unit, unitSchema, onChange, onSelect }: M_ManageTableCell_Props) => {
	return (
		<TableCell className='m-0 p-0 border-r border-color-background-dark'>
			<input
				id={id}
				className='w-full p-2 pl-4 border border-transparent border-inset rounded-none bg-transparent focus-visible:outline-none focus-visible:border-color-brand-medium focus-visible:bg-color-brand-extra-light placeholder:text-color-grayscale-light placeholder:opacity-40'
				placeholder={unitSchema.placeholder}
				defaultValue={Object.keys(unit.unit_data ?? {}).includes(unitSchema.label) ? unit.unit_data[unitSchema.label] : ''}
				onChange={(e) => onChange(unit.unit_id, e.target.value)}
				onSelect={onSelect}
			/>
		</TableCell>
	);
};
