import { z } from 'zod';
import { createSlice } from '@reduxjs/toolkit';
import { Unit } from '@/lib/types/unit.types';

interface UnitsState {
	units: z.infer<typeof Unit>[] | undefined;
}

const initialState: UnitsState = {
	units: undefined,
};

export const unitsSlice = createSlice({
	name: 'unitsSlice',
	initialState,
	reducers: {
		setUnits: (state, action) => {
			state.units = action.payload ?? undefined;
		},
		clearUnits: (state) => {
			state.units = initialState.units;
		},
	},
});

export const { setUnits, clearUnits } = unitsSlice.actions;
