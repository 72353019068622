import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@/styles/ui-components/button';
import { PlaidService } from '@/services/plaid.service';
import { usePlaidLink } from 'react-plaid-link';
import { Loader2 } from 'lucide-react';

interface M_Plaid_Link_Props extends React.HTMLAttributes<HTMLDivElement> {}

export const M_Plaid_Link = ({}: M_Plaid_Link_Props) => {
	const { teamId, propertyId } = useParams();
	const [linkToken, setLinkToken] = useState<string | null>(null);

	useEffect(() => {
		if (linkToken === null) {
			PlaidService.createLinkToken(teamId ?? '', propertyId ?? '', `${window.location.protocol}//${window.location.host}`).then((linkToken: string) =>
				setLinkToken(linkToken)
			);
		}
	}, [linkToken]);

	const { open, ready } = usePlaidLink({
		token: linkToken,
		onSuccess: (public_token) => {
			PlaidService.exchangePublicToken(teamId ?? '', propertyId ?? '', public_token);
		},
	});

	return (
		<Button variant='brand' size='regular' className='w-fit mt-3' onClick={() => open()} disabled={!ready}>
			<img src='/assets/branding/plaid-logo-light.svg' alt='Plaid' className='w-4 h-4' />
			{!ready ? <Loader2 className='w-4 h-4 text-color-grayscale-light animate-spin' /> : 'Connect an account'}
		</Button>
	);
};
