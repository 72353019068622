import dynamicIconImports from 'lucide-react/dynamicIconImports';
import { lazy, Suspense } from 'react';
import { LucideProps } from 'lucide-react';
import { motion } from 'framer-motion';

const fallback = <div className='bg-color-background-dark h-4 w-4 rounded-full animate-pulse' />;

interface IconProps extends Omit<LucideProps, 'ref'> {
	name: keyof typeof dynamicIconImports;
}

const Icon = ({ name, ...props }: IconProps) => {
	const LucideIcon = lazy(() => {
		const importFunction = dynamicIconImports[name];
		if (typeof importFunction !== 'function') return dynamicIconImports['circle-alert']();
		return importFunction();
	});

	return (
		<Suspense fallback={fallback}>
			<motion.div initial={{ scale: 0.5, opacity: 0 }} animate={{ scale: 1, opacity: 1 }}>
				<LucideIcon {...props} />
			</motion.div>
		</Suspense>
	);
};

export default Icon;
