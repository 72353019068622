import React from 'react';
import { Globe, Mail, PartyPopper, Pencil, Verified } from 'lucide-react';
import { motion } from 'framer-motion';
import { Badge } from '@/styles/ui-components/badge';

interface M_OnboardingTeam_Complete_Props extends React.HTMLAttributes<HTMLDivElement> {
	name: string;
	domain: string | undefined;
	emails: string[];
	plan: 'pro' | 'basic';
}

export const M_OnboardingTeam_Complete = ({ name, domain, emails, plan }: M_OnboardingTeam_Complete_Props) => {
	return (
		<div className='relative overflow-clip flex flex-col'>
			<div className='relative flex items-center justify-center bg-color-background-medium border border-color-background-dark h-28 overflow-clip'>
				<motion.div
					className='z-20 p-3 bg-color-background-light border border-color-background-dark rounded-full w-fit flex flex-col gap-2'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				>
					<PartyPopper strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
				</motion.div>

				<motion.div
					className='absolute z-10 h-[42px] w-[42px] bg-[radial-gradient(circle,_var(--color-background-dark)_0%,_transparent_100%)] border border-color-background-dark rounded-full animate-ping-slow'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				/>
			</div>

			<div className='flex flex-col gap-2 px-4 py-6'>
				<motion.div
					className='mb-2'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}
				>
					<Badge variant='brand' size='regular'>
						{plan.charAt(0).toUpperCase() + plan.slice(1).toLowerCase()}
					</Badge>
				</motion.div>

				<motion.div
					className='text-xl text-color-grayscale-dark font-normal'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.1, ease: 'easeInOut' }}
				>
					{name}
				</motion.div>

				<motion.div
					className='text-xs text-color-grayscale-medium font-normal leading-relaxed'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.2, ease: 'easeInOut' }}
				>
					Your team is now configured. Click the complete button to create your team and start using Dwelling. Don't worry if you missed anything
					here, you can always make changes later in the dashboard.
				</motion.div>

				<motion.div
					className='flex flex-row items-center gap-2 text-xs text-color-grayscale-medium font-normal mt-4'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.3, ease: 'easeInOut' }}
				>
					<Pencil strokeWidth={1.5} className='h-4 w-4 text-color-background-placeholder' />
					{name}
				</motion.div>

				<motion.div
					className='flex flex-row items-center gap-2 text-xs text-color-grayscale-medium font-normal mt-2'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.4, ease: 'easeInOut' }}
				>
					<Globe strokeWidth={1.5} className='h-4 w-4 text-color-background-placeholder' />
					{domain ? `Access for ${domain} emails` : 'No domain access'}
				</motion.div>

				<motion.div
					className='flex flex-row items-center gap-2 text-xs text-color-grayscale-medium font-normal mt-2'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.5, ease: 'easeInOut' }}
				>
					<Verified strokeWidth={1.5} className='h-4 w-4 text-color-background-placeholder' />
					{plan.charAt(0).toUpperCase() + plan.slice(1).toLowerCase()} Plan
				</motion.div>

				<motion.div
					className='flex flex-row items-center gap-2 text-xs text-color-grayscale-medium font-normal mt-2'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.6, ease: 'easeInOut' }}
				>
					<Mail strokeWidth={1.5} className='h-4 w-4 text-color-background-placeholder' />
					{emails.length} Email Invitation{emails.length !== 1 ? 's' : ''}
				</motion.div>
			</div>
		</div>
	);
};
