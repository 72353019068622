import { z } from 'zod';

export const Team = z.object({
	team_id: z.string(),
	name: z.string(),
	domain: z.string(),
	plan: z.string(),
});

export const TeamMembership = z.object({
	team_id: z.string(),
	user_id: z.string(),
	role: z.string(),
});
