import React from 'react';
import { BriefcaseBusiness } from 'lucide-react';
import { motion } from 'framer-motion';
import { z } from 'zod';
import { Form, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/styles/ui-components/form';
import { Input } from '@/styles/ui-components/input';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
	name: z.string(),
	domainAllow: z.string().optional(),
});

const NameFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='name'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Name</FormLabel>
				<FormControl>
					<Input autoFocus type='text' {...field} required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const DomainAllowFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='domainAllow'
		render={({ field }) => (
			<FormItem>
				<FormControl>
					<Input prefixClassName='min-w-0' prefix='@' placeholder='dwelling.com' type='text' {...field} required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

interface M_OnboardingTeam_Intro_Props extends React.HTMLAttributes<HTMLDivElement> {
	name: string;
	domain: string | undefined;
	onChangeName: (name: string) => void;
	onChangeDomainAllow: (domainAllow: string | undefined) => void;
}

export const M_OnboardingTeam_Intro = ({ name, domain, onChangeName, onChangeDomainAllow }: M_OnboardingTeam_Intro_Props) => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: name,
			domainAllow: domain,
		},
	});

	const handleChange = (values: z.infer<typeof formSchema>) => {
		onChangeName(values.name);
		onChangeDomainAllow(values.domainAllow || undefined);
	};

	return (
		<div className='relative overflow-clip flex flex-col'>
			<div className='relative flex items-center justify-center bg-color-background-medium border border-color-background-dark h-28 overflow-clip'>
				<motion.div
					className='z-20 p-3 bg-color-background-light border border-color-background-dark rounded-full w-fit flex flex-col gap-2'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				>
					<BriefcaseBusiness strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
				</motion.div>

				<motion.div
					className='absolute z-10 h-[42px] w-[42px] bg-[radial-gradient(circle,_var(--color-background-dark)_0%,_transparent_100%)] border border-color-background-dark rounded-full animate-ping-slow'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				/>
			</div>

			<div className='flex flex-col gap-2 px-4 py-6'>
				<motion.div
					className='text-xl text-color-grayscale-dark font-normal'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}
				>
					Create a Team
				</motion.div>

				<motion.div
					className='text-xs text-color-grayscale-medium font-normal leading-relaxed'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.1, ease: 'easeInOut' }}
				>
					You've made it. Let's get your team started. If you need help along the way, we're here to help.
				</motion.div>

				<FormProvider {...form}>
					<Form {...form}>
						<form onChange={form.handleSubmit(handleChange)} className='flex flex-col gap-2'>
							<motion.div
								initial={{ y: '5px', opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ duration: 0.3, delay: 0.2, ease: 'easeInOut' }}
							>
								<NameFormField form={form} />
							</motion.div>

							<motion.div
								className='flex flex-col gap-2'
								initial={{ y: '5px', opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ duration: 0.3, delay: 0.3, ease: 'easeInOut' }}
							>
								<div className='text-xs text-color-grayscale-medium font-normal leading-relaxed mt-2'>
									You can optionally enable team access for anyone using your domain name. If you leave this field blank, each member will
									have to be individually invited.
								</div>
								<DomainAllowFormField form={form} />
							</motion.div>
						</form>
					</Form>
				</FormProvider>
			</div>
		</div>
	);
};
