import { store } from '@/lib/redux/store';
import { BaseService } from './_base.service';
import { setUnits } from '@/lib/redux/units.store';

/**
 * @class UnitService
 * Service for interacting with unit routes in the core-api
 */
export class UnitService extends BaseService {
	public static list = async (teamId: string, propertyId: string): Promise<Record<string, any>[]> => {
		const response = await fetch(this.getCoreUrl('unit/list'), {
			method: 'POST',
			body: JSON.stringify({ access_token: this.getAccessToken(), team_id: teamId, property_id: propertyId }),
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getAccessToken()}` },
		});

		const res = await response.json();
		store.dispatch(setUnits(res.units.map((unit: any) => unit.unit_data)));
		return res.units;
	};

	public static bulkUpdate = async (teamId: string, units: any[]): Promise<void> => {
		try {
			await fetch(this.getCoreUrl('unit/bulk-update'), {
				method: 'POST',
				body: JSON.stringify({ access_token: this.getAccessToken(), team_id: teamId, units }),
				headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getAccessToken()}` },
			});
		} catch (e: unknown) {
			throw new Error('Failed to bulk update units', { cause: e });
		}
	};
}
