import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { UseFormReturn, useForm } from 'react-hook-form';
import { Loader2 } from 'lucide-react';
import { AuthenticationService } from '@/services/authentication.service';
import { Button } from '@/styles/ui-components/button';
import { Input } from '@/styles/ui-components/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/styles/ui-components/form';
import { useNavigate } from 'react-router-dom';
import { AuthWrapper } from './_AuthWrapper';

const formSchema = z.object({
	given_name: z.string().min(2, 'Given name must be at least 2 characters long'),
	family_name: z.string().min(2, 'Family name must be at least 2 characters long'),
	email: z.string(),
	password: z.string().min(8, 'Password must be at least 8 characters long'),
	confirm_password: z.string().min(8, 'Confirm password must be at least 8 characters long'),
});

const GivenNameFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='given_name'
		render={({ field }) => (
			<FormItem>
				<FormLabel>First name</FormLabel>
				<FormControl>
					<Input type='text' {...field} required autoFocus />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const FamilyNameFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='family_name'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Last name</FormLabel>
				<FormControl>
					<Input type='text' {...field} required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const EmailFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='email'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Email address</FormLabel>
				<FormControl>
					<Input type='email' {...field} required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const PasswordFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='password'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Password</FormLabel>
				<FormControl>
					<Input type='password' {...field} required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const ConfirmPasswordFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='confirm_password'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Confirm password</FormLabel>
				<FormControl>
					<Input type='password' {...field} required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

interface SignUpProps extends React.HTMLAttributes<HTMLDivElement> {}

export const SignUp = ({}: SignUpProps) => {
	useEffect(() => {
		document.title = 'Dwelling • Sign Up';
	}, []);

	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			given_name: '',
			family_name: '',
			email: '',
			password: '',
			confirm_password: '',
		},
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setLoading(true);
		const response = await AuthenticationService.signUp(values.given_name, values.family_name, values.email, values.password, values.confirm_password);
		if (response.status === 'verify') navigate(response.redirect as string);
		else if (response.status === 'error') setError(response.message as string);
		setLoading(false);
	};

	return (
		<AuthWrapper title='Sign up.' description='Welcome to Dwelling. Please enter your details below to continue. Creating an account is completely free.'>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-2'>
					<div className='flex flex-row gap-2'>
						<GivenNameFormField form={form} />
						<FamilyNameFormField form={form} />
					</div>
					<EmailFormField form={form} />
					<PasswordFormField form={form} />
					<ConfirmPasswordFormField form={form} />

					<Button variant='brand' className='w-full flex flex-row gap-2 items-center justify-center mt-4' type='submit' disabled={loading}>
						{loading && <Loader2 className='w-4 h-4 animate-spin' />}
						{!loading && 'Sign up'}
					</Button>

					{error && <div className='mt-2 text-xs text-red-600 leading-relaxed'>{error}</div>}
				</form>
			</Form>

			<div className='flex flex-row gap-4 w-full mt-2'>
				<Button variant='ghost' className='flex-1' onClick={() => navigate('/auth/sign-in')}>
					Back to sign in
				</Button>
			</div>
		</AuthWrapper>
	);
};
