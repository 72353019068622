import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { M_UserDropdown } from '@/app-modules/m-user/m-userDropdown';
import { RootNavigation_Team } from '@/routes/RootNavigation_Team';
import { RootNavigation_Property } from './RootNavigation_Property';
import { motion } from 'framer-motion';

const LogoIcon = ({ onClick }: { onClick: () => void }) => (
	<div
		className='flex flex-row items-center justify-center gap-2 h-full min-h-[50px] w-[50px] min-w-[50px] cursor-pointer hover:scale-110 transition-all'
		onClick={onClick}
	>
		<img src='/assets/branding/logo-icon.svg' alt='Dwelling Logo' className='h-4' />
	</div>
);

export const RootNavigation = () => {
	const navigate = useNavigate();
	const { teamId } = useParams();

	return (
		<motion.div className='relative flex flex-col h-screen w-screen overflow-clip bg-color-background-light'>
			<motion.div className='absolute top-0 left-0 w-full h-[50px] flex flex-row items-center'>
				<LogoIcon onClick={() => navigate('/account')} />
				<div className='w-full flex flex-row gap-4 pl-4 px-2'>
					<RootNavigation_Team />
					{teamId && <RootNavigation_Property />}
					<M_UserDropdown className='ml-auto' />
				</div>
			</motion.div>

			<motion.div
				className='absolute top-0 left-0 h-full border-t border-color-background-dark bg-color-background-light overflow-clip w-full'
				initial={{ top: 0 }}
				animate={{ top: '50px' }}
				transition={{ duration: 0.3, delay: 0.3 }}
			>
				<Outlet />
			</motion.div>
		</motion.div>
	);
};
