import { createSlice } from '@reduxjs/toolkit';
import { z } from 'zod';
import { Team, TeamMembership } from '../types';

interface TeamsState {
	teams: z.infer<typeof Team>[] | undefined;
	teamMemberships: z.infer<typeof TeamMembership>[] | undefined;
}

const initialState: TeamsState = {
	teams: undefined,
	teamMemberships: undefined,
};

export const teamsSlice = createSlice({
	name: 'teamsState',
	initialState,
	reducers: {
		setTeamsState: (state, action) => {
			state.teams = action.payload.teams ?? undefined;
			state.teamMemberships = action.payload.teamMemberships ?? undefined;
		},
		clearTeamsState: (state) => {
			state.teams = initialState.teams;
			state.teamMemberships = initialState.teamMemberships;
		},
	},
});

export const { setTeamsState, clearTeamsState } = teamsSlice.actions;
