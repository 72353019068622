import useSWR, { SWRResponse } from 'swr';

/**
 * @class BaseService
 */
export class BaseService {
	protected static getAccessToken = (): string | null => {
		return localStorage.getItem('access_token');
	};

	protected static clearAccessToken = (): void => {
		localStorage.removeItem('access_token');
	};

	protected static getAuthUrl = (path: string): string => {
		return process.env.NODE_ENV !== 'production'
			? `https://beta.manage-auth.api.usedwelling.com/${path}`
			: `https://manage-auth.api.usedwelling.com/${path}`;
	};

	protected static getCoreUrl = (path: string): string => {
		return process.env.NODE_ENV !== 'production'
			? `https://beta.manage-core.api.usedwelling.com/${path}`
			: `https://manage-core.api.usedwelling.com/${path}`;
	};

	protected static fetch = (
		url: string,
		method: 'POST' | 'GET' | 'PUT' | 'DELETE' = 'POST',
		body: Record<string, any> = {},
		callback: <T>(response: Response) => Promise<T>
	): SWRResponse =>
		useSWR(url, async (url) => {
			const accessToken = this.getAccessToken();
			const response = await fetch(url, {
				method: method,
				body: JSON.stringify({ access_token: accessToken, ...body }),
				headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
			});
			return await callback(response);
		});
}
