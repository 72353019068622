import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { Button } from '@/styles/ui-components/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/styles/ui-components/popover';
import { ChevronsUpDown, Plus } from 'lucide-react';
import { M_OnboardingTeam } from '@/app-modules/m-onboardingTeam/m-onboardingTeam';
import { clearProperties } from '@/lib/redux/properties.store';
import { PropertyService } from '@/services/property.service';
import { Team } from '@/lib/types';
import { z } from 'zod';
import { Badge } from '@/styles/ui-components/badge';

export const RootNavigation_Team = () => {
	const navigate = useNavigate();
	const { teamId } = useParams();
	const dispatch = useAppDispatch();
	const teams = useAppSelector((state) => state.teams);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const closePopover = () => setIsPopoverOpen(false);

	const [team, setTeam] = useState<any | undefined>(undefined);
	useEffect(() => {
		if (teams.teams !== undefined && teams.teams.length > 0) setTeam(teams.teams?.find((team: any) => team.team_id === teamId));
	}, [teams.teams, teamId]);

	useEffect(() => {
		if (teamId) {
			dispatch(clearProperties());
			PropertyService.list(teamId);
		}
	}, [teamId]);

	return (
		<div className='flex flex-row items-center'>
			{/* <Avatar className={cn('border-indigo-700 bg-indigo-700 mr-2', teamId && propertyId === undefined ? 'opacity-100' : 'opacity-25')}>
				<AvatarFallback className='text-indigo-50'>
					{(teams.teams && teams.teams.length > 0 && team ? team.name[0] : '?')[0].toUpperCase()}
				</AvatarFallback>
			</Avatar> */}

			<div className='flex flex-row'>
				<Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
					<PopoverTrigger asChild>
						{team ? (
							<Button variant='ghost' size='small' className='flex flex-row gap-2 items-center text-xs font-normal'>
								{team.name}
								<ChevronsUpDown strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
							</Button>
						) : (
							<Button variant='ghost' size='small' className='flex flex-row gap-2 items-center text-xs font-normal'>
								Select a team
								<ChevronsUpDown strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
							</Button>
						)}
					</PopoverTrigger>
					<PopoverContent className='max-w-[250px] mx-2'>
						<div className='p-1 flex flex-col'>
							{(teams.teams === undefined || teams.teams.length === 0) && (
								<div className='flex flex-col p-3 gap-1'>
									<div className='text-sm text-color-grayscale-dark'>No teams found</div>
									<div className='text-xs text-color-grayscale-medium leading-relaxed'>
										There are no teams associated with this account. Create a new team to get started.
									</div>
								</div>
							)}
							{teams.teams &&
								teams.teams.length > 0 &&
								teams.teams.map((team: z.infer<typeof Team>) => (
									<Button
										key={team.team_id}
										variant='ghost'
										size='regular'
										className='w-full p-2 pl-3'
										onClick={() => {
											closePopover();
											navigate(`/${team.team_id}`);
										}}
									>
										<span className='mr-auto'>{team.name}</span>
										<Badge variant='muted' className='ml-auto'>
											{_.startCase(team.plan)}
										</Badge>
									</Button>
								))}
						</div>
					</PopoverContent>
				</Popover>

				<M_OnboardingTeam>
					<Button variant='ghost' size='small'>
						<Plus strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light ml-auto' />
					</Button>
				</M_OnboardingTeam>
			</div>
		</div>
	);
};
