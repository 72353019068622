import _ from 'lodash';
import { Button } from '@/styles/ui-components/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/styles/ui-components/popover';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { ArrowRightToLine, Blend, ChevronDown, LifeBuoy, Settings2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { setTheme } from '@/lib/redux/_theme.store';

interface M_UserDropdown_Props extends React.HTMLAttributes<HTMLDivElement> {}

export const M_UserDropdown = ({ className }: M_UserDropdown_Props) => {
	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const _theme = useAppSelector((state) => state._theme);
	const user = useAppSelector((state) => state.user);

	const handleSignOut = () => {
		localStorage.removeItem('access_token');
		window.location.href = '/auth/sign-in';
	};

	return (
		<Popover>
			<PopoverTrigger className={className}>
				<Button variant='ghost' size='small' className='group'>
					<span className='text-xs font-normal whitespace-nowrap'>
						{user.givenName} {user.familyName}
					</span>

					<ChevronDown strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light ml-auto' />
				</Button>
			</PopoverTrigger>
			<PopoverContent className='flex flex-col p-1 mx-2 w-[250px] max-w-[250px]'>
				<div className='flex flex-col gap-0.5 px-4 py-2'>
					<div className='text-sm text-color-grayscale-dark font-normal whitespace-nowrap'>
						{user.givenName} {user.familyName}
					</div>
					<div className='text-xs text-color-grayscale-light font-normal whitespace-nowrap'>{user.email}</div>
				</div>

				<Button variant='ghost' size='regular' onClick={() => navigate('/account')}>
					Account
					<Settings2 strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light ml-auto' />
				</Button>

				<Button variant='ghost' size='regular' onClick={() => navigate('/support')}>
					Support
					<LifeBuoy strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light ml-auto' />
				</Button>

				<Button variant='ghost' size='regular' onClick={() => dispatch(setTheme(_theme.theme === 'light' ? 'dark' : 'light'))}>
					Switch Theme
					<Blend strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light ml-auto' />
				</Button>

				<Button variant='ghost' size='regular' onClick={() => handleSignOut()}>
					Log out
					<ArrowRightToLine strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light ml-auto' />
				</Button>
			</PopoverContent>
		</Popover>
	);
};
