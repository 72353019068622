import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog, DialogContent, DialogTrigger } from '@/styles/ui-components/dialog';
import { Button } from '@/styles/ui-components/button';
import { M_OnboardingProperty_Intro } from './m-onboardingProperty-Intro';
import { Loader2 } from 'lucide-react';
import { PropertyService } from '@/services/property.service';
import { M_OnboardingProperty_Address } from './m-onboardingProperty-Address';
import { M_OnboardingProperty_Review } from './m-onboardingProperty-Review';

interface M_OnboardingProperty_Props extends React.HTMLAttributes<HTMLDivElement> {}

export const M_OnboardingProperty = ({ children }: M_OnboardingProperty_Props) => {
	const { teamId } = useParams();
	const navigate = useNavigate();

	const [formRenderKey, setFormRenderKey] = useState<number>(() => Date.now());
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);

	const [country, setCountry] = useState<string>('United States of America');
	const [region, setRegion] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [address, setAddress] = useState<string>('');
	const [postcode, setPostcode] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [yearBuilt, setYearBuilt] = useState<number | undefined>(undefined);
	const [numberOfUnits, setNumberOfUnits] = useState<number | undefined>(undefined);

	const [step, setStep] = useState<number>(0);
	const steps = [
		{
			element: (
				<M_OnboardingProperty_Intro
					name={name}
					yearBuilt={yearBuilt}
					numberOfUnits={numberOfUnits}
					setName={setName}
					setYearBuilt={setYearBuilt}
					setNumberOfUnits={setNumberOfUnits}
				/>
			),
			isValid: () => name !== '' && yearBuilt !== undefined && numberOfUnits !== undefined,
		},
		{
			element: (
				<M_OnboardingProperty_Address
					country={country}
					state={region}
					city={city}
					address={address}
					zip={postcode}
					setCountry={setCountry}
					setState={setRegion}
					setCity={setCity}
					setAddress={setAddress}
					setZip={setPostcode}
				/>
			),
			isValid: () => country !== '' && region !== '' && city !== '' && address !== '' && postcode !== '',
		},
		{
			element: (
				<M_OnboardingProperty_Review
					name={name}
					country={country}
					state={region}
					city={city}
					address={address}
					zip={postcode}
					yearBuilt={yearBuilt}
					numberOfUnits={numberOfUnits}
				/>
			),
			isValid: () => true,
		},
	];

	const resetForm = () => {
		setCountry('');
		setRegion('');
		setCity('');
		setAddress('');
		setPostcode('');
		setName('');
		setYearBuilt(undefined);
		setNumberOfUnits(undefined);
		setStep(0);
		setFormRenderKey(Date.now());
	};

	const createProperty = async () => {
		setIsLoading(true);
		const id = await PropertyService.create(teamId ?? '', name, yearBuilt, numberOfUnits, country, region, city, address, postcode);
		await PropertyService.list(teamId ?? '');
		if (id !== undefined) {
			setOpen(false);
			resetForm();
			navigate(`/${teamId}/${id}`);
		}
		resetForm();

		setIsLoading(false);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent className='flex flex-col max-w-sm h-[600px] overflow-clip'>
				<div key={formRenderKey} className='flex flex-col flex-1 h-full w-full'>
					{steps[step].element}

					<div className='flex flex-row gap-2 p-4 border-t border-color-background-dark bg-color-background-light w-full mt-auto'>
						{(country !== 'United States of America' ||
							region !== '' ||
							city !== '' ||
							address !== '' ||
							postcode !== '' ||
							name !== '' ||
							yearBuilt !== undefined ||
							numberOfUnits !== undefined) && (
							<Button variant='ghost' size='small' className='mr-2' onClick={resetForm}>
								Reset
							</Button>
						)}

						<div className='flex flex-row gap-2 items-center mr-auto'>
							{steps.map((_, index) => (
								<div
									key={index}
									className={`w-2 h-2 rounded-full transition-all ${index === step ? 'bg-color-brand-medium' : 'bg-color-background-dark'}`}
								/>
							))}
						</div>

						{step > 0 && (
							<Button variant='outline' size='regular' onClick={() => setStep(step - 1)}>
								Back
							</Button>
						)}

						{step < steps.length - 1 ? (
							<Button variant='brand' size='regular' onClick={() => setStep(step + 1)} disabled={!steps[step].isValid()}>
								Next
							</Button>
						) : (
							<Button variant='brand' size='regular' disabled={isLoading || !steps[step].isValid()} onClick={createProperty}>
								{isLoading ? <Loader2 className='w-4 h-4 animate-spin' /> : 'Add property'}
							</Button>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};
