import { cn } from '@/lib/utils';
import Icon from '@/styles/ui-components/icon';
import { TableHead } from '@/styles/ui-components/table';
import _ from 'lodash';
import { Asterisk } from 'lucide-react';
import dynamicIconImports from 'lucide-react/dynamicIconImports';

interface M_ManageTableHead_Props {
	icon: keyof typeof dynamicIconImports;
	label: string;
	required?: boolean;
}

export const M_ManageTableHead = ({ icon, label, required }: M_ManageTableHead_Props) => {
	return (
		<TableHead className={cn(`h-full w-full p-0 sticky top-0 z-10 bg-color-background-light border-r last:border-r-0 border-color-background-dark group`)}>
			<div className='p-3 h-full w-full flex flex-row items-center gap-2 border-b border-color-background-dark'>
				<Icon name={icon} strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
				<span className='mr-auto'>{_.startCase(label)}</span>

				{required && <Asterisk strokeWidth={1.5} className='h-4 w-4 text-red-700' />}
			</div>
		</TableHead>
	);
};
