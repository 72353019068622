import { Button } from '@/styles/ui-components/button';
import { M_OnboardingProperty } from '@/app-modules/m-onboardingProperty/m-onboardingProperty';
import { Building, Minus, Plus } from 'lucide-react';
import { HTMLAttributes, useState } from 'react';
import { useAppSelector } from '@/lib/hooks';
import { Property } from '@/lib/types/property.types';
import { z } from 'zod';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Input } from '@/styles/ui-components/input';
import { Skeleton } from '@/styles/ui-components/skeleton';

interface Team_Properties_Props extends HTMLAttributes<HTMLDivElement> {}

export const Team_Properties = ({}: Team_Properties_Props) => {
	const navigate = useNavigate();
	const { teamId } = useParams();
	const { properties } = useAppSelector((state) => state.properties);

	const [search, setSearch] = useState<string>('');

	return (
		<div className='flex flex-col'>
			<div className='flex flex-row items-center gap-4 p-4 pb-0'>
				<Input placeholder='Search your properties...' className='w-full' value={search} onChange={(e) => setSearch(e.target.value)} />

				<M_OnboardingProperty>
					<Button variant='indigo' size='regular' className='whitespace-nowrap'>
						<Plus strokeWidth={1.5} className='h-4 w-4 text-indigo-50' />
						Add a property
					</Button>
				</M_OnboardingProperty>
			</div>

			<AnimatePresence mode='wait'>
				{!properties && (
					<motion.div
						className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 w-full'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { delay: 0, duration: 0.3 } }}
						exit={{ opacity: 0, transition: { delay: 0, duration: 0.3 } }}
					>
						{Array.from({ length: 12 }).map((_, index) => (
							<div className='w-full h-full'>
								<Skeleton key={index} className='col-span-1 row-span-1 w-full h-36' />
							</div>
						))}
					</motion.div>
				)}
			</AnimatePresence>

			<AnimatePresence mode='wait'>
				{properties && properties.length === 0 && (
					<motion.div
						className='flex flex-col gap-2 px-4 py-24 w-full items-center'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { delay: 0.3, duration: 0.3 } }}
						exit={{ opacity: 0, transition: { delay: 0, duration: 0.3 } }}
					>
						<div className='flex flex-row gap-2 mb-4'>
							<Minus strokeWidth={1.5} className='h-6 w-6 text-color-brand-light' />
							<Building strokeWidth={1.5} className='h-6 w-6 text-color-brand-medium' />
							<Plus strokeWidth={1.5} className='h-6 w-6 text-color-brand-light' />
						</div>

						<div className='text-xl text-color-grayscale-dark'>No properties found</div>
						<div className='text-xs text-color-grayscale-medium leading-relaxed max-w-xs text-center'>
							There are no properties associated with this team. Add a property to get started.
						</div>
					</motion.div>
				)}
			</AnimatePresence>

			<AnimatePresence mode='wait'>
				{properties && properties.length > 0 && (
					<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
						{properties
							.filter((property) => {
								const searchLower = search.toLowerCase();
								return (
									property.name.toLowerCase().includes(searchLower) ||
									property.location.address.toLowerCase().includes(searchLower) ||
									property.location.city.toLowerCase().includes(searchLower) ||
									property.location.region.toLowerCase().includes(searchLower) ||
									property.location.country.toLowerCase().includes(searchLower) ||
									property.location.postcode.toLowerCase().includes(searchLower) ||
									property.year_built.toString().includes(searchLower)
								);
							})
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((property: z.infer<typeof Property>, index: number) => (
								<motion.div
									key={property.property_id}
									initial={{ opacity: 0, y: 10 }}
									animate={{ opacity: 1, y: 0, transition: { delay: index * 0.1 + 0.3, duration: 0.3 } }}
									exit={{ opacity: 0, y: 10, transition: { delay: 0, duration: 0.3 } }}
									className='flex flex-col border border-color-background-dark rounded-lg overflow-clip'
								>
									<div className='text-xl text-color-grayscale-dark font-normal p-4 pb-2'>{property.name}</div>
									<div className='text-xs text-color-grayscale-medium font-normal leading-relaxed max-w-sm p-4 pt-0'>
										{property.name} was built in {property.year_built} and is located at {property.location.address},{' '}
										{property.location.city}, {property.location.region} {property.location.postcode}, {property.location.country}.
									</div>

									<div className='flex flex-row gap-0 p-1 border-t border-color-background-dark'>
										<Button variant='ghost' size='small' className='w-full' onClick={() => navigate(`/${teamId}/${property.property_id}`)}>
											Overview
										</Button>
										<Button
											variant='ghost'
											size='small'
											className='w-full'
											onClick={() => navigate(`/${teamId}/${property.property_id}/manage`)}
										>
											Manage
										</Button>
										<Button
											variant='ghost'
											size='small'
											className='w-full'
											onClick={() => navigate(`/${teamId}/${property.property_id}/payments`)}
										>
											Payments
										</Button>
										<Button
											variant='ghost'
											size='small'
											className='w-full'
											onClick={() => navigate(`/${teamId}/${property.property_id}/settings`)}
										>
											Settings
										</Button>
									</div>
								</motion.div>
							))}
					</div>
				)}
			</AnimatePresence>
		</div>
	);
};
