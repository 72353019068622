import { cn } from '@/lib/utils';
import { Badge } from '@/styles/ui-components/badge';
import { Button } from '@/styles/ui-components/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/styles/ui-components/dialog';
import { Input } from '@/styles/ui-components/input';
import { Switch } from '@/styles/ui-components/switch';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/styles/ui-components/tooltip';
import { AnimatePresence, motion } from 'framer-motion';
import { ArrowBigUpDash, ArrowUp, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Command, Keyboard, Mouse, Search } from 'lucide-react';
import { HTMLAttributes } from 'react';

interface M_ManageToolbar_Props extends Omit<HTMLAttributes<HTMLDivElement>, 'autoSave'> {
	// AutoSave State
	autoSave: boolean;
	setAutoSave: (state: boolean) => void;

	// Save State
	renderSave: boolean;
	loadingSave: boolean;
	handleSave: () => void;

	// Scroll to top
	renderScrollToTop: boolean;
	handleScrollToTop: () => void;

	// Split full width (for bottom of page rendering)
	fullWidthInput?: boolean;
}

export const M_ManageToolbar = ({
	autoSave,
	setAutoSave,
	renderSave,
	loadingSave,
	handleSave,
	renderScrollToTop,
	handleScrollToTop,
	fullWidthInput = false,
	...props
}: M_ManageToolbar_Props) => {
	return (
		<div {...props} className={cn('flex flex-row p-2', props.className)}>
			<AnimatePresence mode='wait'>
				{renderScrollToTop && (
					<motion.div
						className='flex flex-row gap-2'
						initial={{ width: 0 }}
						animate={{ width: 'auto', transition: { delay: 0 } }}
						exit={{ width: 0, transition: { delay: 0.3 } }}
					>
						<motion.div
							className='flex flex-row gap-2 mr-2'
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { delay: 0.3 } }}
							exit={{ opacity: 0, transition: { delay: 0 } }}
						>
							<Tooltip>
								<TooltipTrigger>
									<Button variant='outline' size='small' onClick={handleScrollToTop}>
										<Mouse strokeWidth={1.5} className='w-4 h-4 text-color-grayscale-light' />
										<ArrowUp strokeWidth={1.5} className='w-4 h-4 text-color-grayscale-light' />
									</Button>
								</TooltipTrigger>
								<TooltipContent>Scroll to top</TooltipContent>
							</Tooltip>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>

			<Dialog>
				<DialogTrigger asChild>
					<Button variant='outline' size='small' className='mr-2'>
						<Keyboard strokeWidth={1.5} className='w-4 h-4 text-color-grayscale-light' />
					</Button>
				</DialogTrigger>
				<DialogContent className='overflow-clip'>
					<DialogHeader>
						<DialogTitle>Keyboard Shortcuts</DialogTitle>
						<DialogDescription>Use keyboard shortcuts to navigate and edit efficiently.</DialogDescription>
					</DialogHeader>

					<div className='flex flex-col gap-1 p-2'>
						<div className='flex flex-row gap-2 p-2 pl-4 border border-color-background-dark bg-color-background-medium rounded items-center'>
							<span className='text-xs text-color-grayscale-medium font-normal mr-auto'>Navigate between cells</span>

							<Badge variant='outline'>
								<ArrowBigUpDash strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
							</Badge>
							<span className='text-xs text-color-grayscale-medium font-normal'>+</span>
							<div className='flex flex-row gap-1'>
								<Badge variant='outline'>
									<ChevronUp strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
								</Badge>
								<Badge variant='outline'>
									<ChevronDown strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
								</Badge>
								<Badge variant='outline'>
									<ChevronLeft strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
								</Badge>
								<Badge variant='outline'>
									<ChevronRight strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
								</Badge>
							</div>
						</div>

						<div className='flex flex-row gap-2 p-2 pl-4 border border-color-background-dark bg-color-background-medium rounded items-center'>
							<span className='text-xs text-color-grayscale-medium font-normal mr-auto'>Select cells</span>

							<Badge variant='outline'>
								<Command strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
							</Badge>
							<span className='text-xs text-color-grayscale-medium font-normal'>+</span>
							<Badge variant='outline'>
								<ArrowBigUpDash strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
							</Badge>
							<span className='text-xs text-color-grayscale-medium font-normal'>+</span>
							<div className='flex flex-row gap-1'>
								<Badge variant='outline'>
									<ChevronUp strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
								</Badge>
								<Badge variant='outline'>
									<ChevronDown strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
								</Badge>
								<Badge variant='outline'>
									<ChevronLeft strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
								</Badge>
								<Badge variant='outline'>
									<ChevronRight strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
								</Badge>
							</div>
						</div>
					</div>
				</DialogContent>
			</Dialog>

			<div className='w-full'>
				<Input type='text' placeholder='What would you like to do?' inputClassName={cn('rounded-r-none', fullWidthInput ? 'w-full' : 'w-96')} />
			</div>
			<Button variant='outline' size='small' className='border-l-0 rounded-l-none'>
				<Search strokeWidth={1.5} className='w-4 h-4 text-color-grayscale-light' />
			</Button>

			<div className='flex flex-row gap-4 items-center ml-4 mr-2 whitespace-nowrap'>
				<span className='text-xs text-color-grayscale-medium font-normal'>AutoSave</span>
				<Switch checked={autoSave} onCheckedChange={() => setAutoSave(!autoSave)} />
			</div>

			<AnimatePresence mode='wait'>
				{!autoSave && (
					<motion.div
						className='flex flex-row gap-2'
						initial={{ width: 0 }}
						animate={{ width: 'auto', transition: { delay: 0 } }}
						exit={{ width: 0, transition: { delay: 0.3 } }}
					>
						<motion.div
							className='flex flex-row gap-2 ml-2'
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { delay: 0.3 } }}
							exit={{ opacity: 0, transition: { delay: 0 } }}
						>
							<Button variant='outline' disabled={!renderSave || loadingSave} className='whitespace-nowrap'>
								Cancel
							</Button>
							<Button
								variant='success'
								disabled={!renderSave || loadingSave}
								loading={loadingSave}
								onClick={handleSave}
								className='whitespace-nowrap gap-0'
							>
								Save changes
							</Button>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
