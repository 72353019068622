import { forwardRef, useState } from 'react';
import { cn } from '@/lib/utils';
import { Eye, EyeOff } from 'lucide-react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	prefix?: string;
	suffix?: string;

	jsxPrefix?: JSX.Element;
	jsxSuffix?: JSX.Element;

	prefixClassName?: string;
	inputClassName?: string;
	suffixClassName?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
	({ className, prefixClassName, inputClassName, suffixClassName, type, prefix, suffix, jsxPrefix, jsxSuffix, ...props }, ref) => {
		const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

		const togglePasswordVisibility = () => {
			setIsPasswordVisible(!isPasswordVisible);
		};

		return (
			<div className={cn('flex flex-row', className)}>
				{(prefix || jsxPrefix) && (
					<div
						className={cn(
							'min-w-[100px] whitespace-nowrap rounded-l flex items-center text-[16px] sm:text-xs text-color-grayscale-light bg-color-background-light border-l border-t border-b border-color-background-dark px-3 py-1.5',
							prefixClassName
						)}
					>
						{prefix} {jsxPrefix}
					</div>
				)}

				<input
					tabIndex={1}
					type={type === 'password' ? (isPasswordVisible ? 'text' : 'password') : type}
					className={cn(
						'transition-all flex w-full rounded border border-color-background-dark bg-transparent text-color-grayscale-medium px-3 py-2 text-[16px] sm:text-xs outline-none placeholder:text-color-background-placeholder placeholder:font-normal focus:border-color-brand-light focus:outline-offset-0 focus:outline-2 focus:outline-color-brand-extra-light',
						(prefix || jsxPrefix) && 'rounded-l-none',
						(suffix || jsxSuffix) && 'rounded-r-none',
						inputClassName
					)}
					ref={ref}
					{...props}
					autoComplete='off'
				/>

				{(suffix || jsxSuffix) && (
					<div
						className={cn(
							'min-w-fit whitespace-nowrap rounded-r flex items-center text-[16px] sm:text-xs text-color-grayscale-light bg-color-background-medium border-r border-t border-b border-color-background-dark px-3 py-1.5',
							suffixClassName
						)}
					>
						{suffix} {jsxSuffix}
					</div>
				)}

				{type === 'password' && (
					<div className='flex items-center cursor-pointer pl-3 text-color-grayscale-light' onClick={togglePasswordVisibility}>
						{isPasswordVisible ? <EyeOff strokeWidth={1.75} className='h-4 w-4' /> : <Eye strokeWidth={1.75} className='h-4 w-4' />}
					</div>
				)}
			</div>
		);
	}
);
Input.displayName = 'Input';
