import { HTMLAttributes } from 'react';
import { z } from 'zod';
import { Building, CircleDashed } from 'lucide-react';
import { motion } from 'framer-motion';
import { Input } from '@/styles/ui-components/input';
import { FormControl, FormField, FormItem, FormLabel } from '@/styles/ui-components/form';
import { Form, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
	name: z.string(),
	year_built: z.coerce.number(),
	number_of_units: z.coerce.number(),
});

const NameFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='name'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Name</FormLabel>
				<FormControl>
					<Input type='text' placeholder='Acme Corp. Apartments' {...field} autoComplete='off' required />
				</FormControl>
			</FormItem>
		)}
	/>
);

const YearBuiltFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='year_built'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Year built</FormLabel>
				<FormControl>
					<Input type='number' placeholder='2024' {...field} autoComplete='off' required />
				</FormControl>
			</FormItem>
		)}
	/>
);

const UnitCountFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='number_of_units'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Number of Units</FormLabel>
				<FormControl>
					<Input type='number' placeholder='0' {...field} autoComplete='off' required />
				</FormControl>
			</FormItem>
		)}
	/>
);

interface M_OnboardingProperty_Intro_Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
	name: string;
	yearBuilt: number | undefined;
	numberOfUnits: number | undefined;
	setName: (name: string) => void;
	setYearBuilt: (year_built: number) => void;
	setNumberOfUnits: (number_of_units: number) => void;
}

export const M_OnboardingProperty_Intro = ({ name, yearBuilt, numberOfUnits, setName, setYearBuilt, setNumberOfUnits }: M_OnboardingProperty_Intro_Props) => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: name,
			year_built: yearBuilt,
			number_of_units: numberOfUnits,
		},
	});

	const onChange = async (values: z.infer<typeof formSchema>) => {
		setName(values.name);
		setYearBuilt(values.year_built);
		setNumberOfUnits(values.number_of_units);
	};

	return (
		<div className='relative overflow-clip flex flex-col'>
			<div className='relative flex items-center justify-center bg-color-background-medium border border-color-background-dark h-28 overflow-clip'>
				<motion.div
					className='z-20 p-3 bg-color-background-light border border-color-background-dark rounded-full w-fit flex flex-col gap-2'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				>
					<Building strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
				</motion.div>

				<motion.div
					className='absolute z-10 h-[42px] w-[42px] bg-[radial-gradient(circle,_var(--color-background-dark)_0%,_transparent_100%)] border border-color-background-dark rounded-full animate-ping-slow'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				/>

				<motion.div
					className='absolute z-10 w-[200px] h-[200px] border border-color-background-dark border-dashed rounded-full'
					initial={{ opacity: 0, rotate: 0 }}
					animate={{
						opacity: 1,
						rotate: 360,
					}}
					transition={{
						opacity: { delay: 0.6, duration: 1.5 },
						rotate: {
							duration: 240,
							repeat: Infinity,
							ease: 'linear',
						},
					}}
				>
					{Array(10)
						.fill(null)
						.map((_, index) => (
							<div
								key={index}
								style={{
									position: 'absolute',
									left: '50%',
									top: '50%',
									transform: `translate(-50%, -50%) rotate(${index * 36}deg) translateY(-100px)`,
								}}
							>
								<motion.div
									className='p-2 bg-color-background-light border border-color-background-dark rounded-full'
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ elay: 0.6, duration: 1.5 }}
								>
									<CircleDashed strokeWidth={1} className='h-4 w-4 text-color-background-placeholder' />
								</motion.div>
							</div>
						))}
				</motion.div>
			</div>

			<div className='flex flex-col gap-2 px-4 py-6'>
				<motion.div
					className='text-xl text-color-grayscale-dark font-normal'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}
				>
					Property Information
				</motion.div>

				<motion.div
					className='text-xs text-color-grayscale-medium font-normal leading-relaxed'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.1, ease: 'easeInOut' }}
				>
					Adding a property is quick and easy. Let's begin with the basics to set up your property. Don't worry, you can update these details later.
				</motion.div>

				<FormProvider {...form}>
					<Form {...form}>
						<form onChange={() => onChange(form.getValues())} className='flex flex-col gap-4'>
							<motion.div
								initial={{ y: '5px', opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ duration: 0.3, delay: 0.2, ease: 'easeInOut' }}
							>
								<NameFormField form={form} />
							</motion.div>

							<motion.div
								initial={{ y: '5px', opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ duration: 0.3, delay: 0.3, ease: 'easeInOut' }}
							>
								<YearBuiltFormField form={form} />
							</motion.div>

							<motion.div
								initial={{ y: '5px', opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ duration: 0.3, delay: 0.4, ease: 'easeInOut' }}
							>
								<UnitCountFormField form={form} />
							</motion.div>
						</form>
					</Form>
				</FormProvider>
			</div>
		</div>
	);
};
