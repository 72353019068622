import { twMerge } from 'tailwind-merge';
import { clsx, type ClassValue } from 'clsx';

export const cn = (...inputs: ClassValue[]) => {
	return twMerge(clsx(inputs));
};

export const formatDate = (input: string | number): string => {
	const date = new Date(input);
	return date.toLocaleDateString('en-US', {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	});
};

export const absoluteUrl = (path: string) => {
	return `${process.env.NEXT_PUBLIC_APP_URL}${path}`;
};

export const largeNumberFormatter = (number: number, digits: number) => {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'G' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' },
	];
	const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
	const item = lookup.findLast((item) => number >= item.value);
	return item ? (number / item.value).toFixed(digits).replace(regexp, '').concat(item.symbol) : '0';
};

export const decimalNumberFormatter = (number: number, decimals = 0) =>
	Intl.NumberFormat('us', {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	})
		.format(Number(number))
		.toString();

export const usNumberformatter = (number: number, decimals = 0) =>
	Intl.NumberFormat('us', {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	})
		.format(Number(number))
		.toString();
