import { HTMLAttributes } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@/lib/hooks';

interface Team_Props extends HTMLAttributes<HTMLDivElement> {}

export const Team = ({}: Team_Props) => {
	const { teamId } = useParams();
	const team = useAppSelector((state) => state.teams.teams?.find((team) => team.team_id === teamId));

	return (
		<div className='flex flex-col h-full bg-color-background-light'>
			<div className='flex flex-col gap-2 p-6 w-full'>
				<div className='text-2xl text-color-grayscale-dark font-normal flex flex-row gap-2 items-center'>{team?.name}</div>
				<div className='text-xs text-color-grayscale-medium font-normal leading-relaxed max-w-lg'>
					Manage your property's units and resident information. Update unit details, track occupancy, and maintain accurate records for each
					residence within your property.
				</div>
			</div>
		</div>
	);
};
