import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';

const badgeVariants = cva('inline-flex items-center border rounded text-xs font-normal', {
	variants: {
		variant: {
			brand: 'text-color-background-light border border-color-brand-medium bg-color-brand-medium',
			brandSecondary: 'text-color-brand-dark border border-color-brand-extra-light bg-color-brand-extra-light',
			solid: 'text-color-background-light border border-color-grayscale-dark bg-color-grayscale-dark',

			outline: 'text-color-grayscale-medium border border-color-background-dark bg-color-background-light hover:bg-color-background-medium',
			link: 'text-color-brand-medium hover:text-color-brand-dark underline-offset-4 hover:underline',
			ghost: 'text-color-grayscale-medium',
			muted: 'text-color-grayscale-medium border border-color-background-medium bg-color-background-medium',

			indigo: 'text-indigo-50 border border-indigo-700 bg-indigo-700',
			success: 'text-green-50 border border-green-700 bg-green-700',
			'success-muted': 'text-green-700 border border-green-50 bg-green-50',
		},
		size: {
			regular: 'px-1.5 py-0.5',
			small: 'px-0.5 py-0.5',
		},
	},
	defaultVariants: {
		variant: 'brand',
		size: 'regular',
	},
});

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
	return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge };
