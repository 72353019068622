import React from 'react';
import { Button } from '@/styles/ui-components/button';
import { useAppSelector } from '@/lib/hooks';
import { CircleDashed, CircleUserRound, Mail, Plus } from 'lucide-react';
import { motion } from 'framer-motion';
import { M_OnboardingTeam } from '@/app-modules/m-onboardingTeam/m-onboardingTeam';
import { Dialog, DialogClose, DialogContent, DialogTrigger } from '@/styles/ui-components/dialog';
import { Avatar, AvatarFallback } from '@/styles/ui-components/avatar';
import { useNavigate } from 'react-router-dom';
import { Team } from '@/lib/types/team.types';
import { z } from 'zod';
import { Badge } from '@/styles/ui-components/badge';
import _ from 'lodash';

interface Account_Props extends React.HTMLAttributes<HTMLDivElement> {}

export const Account = ({}: Account_Props) => {
	const navigate = useNavigate();
	const { givenName } = useAppSelector((state) => state.user);
	const { teams } = useAppSelector((state) => state.teams);
	const _theme = useAppSelector((state) => state._theme);

	return (
		<motion.div className='flex-1 flex justify-center h-[calc(100vh-50px)] w-full bg-color-background-light'>
			<div className='flex flex-col w-[60%] border-x border-color-background-dark'>
				<div className='relative w-full flex flex-col items-center gap-2 border-b border-color-background-dark p-12 overflow-clip'>
					<Avatar className='z-20 mb-6 h-12 w-12 rounded-full overflow-visible'>
						<AvatarFallback className='text-sm'>{givenName?.[0].toUpperCase() || '?'}</AvatarFallback>
					</Avatar>

					<div className='z-20 text-3xl text-color-grayscale-dark font-normal'>Hello {givenName}</div>
					<div className='z-20 text-sm text-color-grayscale-medium font-normal leading-relaxed max-w-md mt-2 text-center'>
						To get started, you'll need to create or join a team. Teams allow you to collaborate with colleagues, manage properties together, and
						streamline your workflow.
					</div>

					<div className='z-20 flex flex-row gap-2 mt-4'>
						<M_OnboardingTeam>
							<Button variant='ghost' size='regular' className='w-fit'>
								<Plus strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
								Create a team
							</Button>
						</M_OnboardingTeam>

						<Dialog>
							<DialogTrigger asChild>
								<Button variant='ghost' size='regular' className='w-fit'>
									<Mail strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
									Join a team
								</Button>
							</DialogTrigger>
							<DialogContent className='flex flex-col max-w-sm overflow-clip'>
								<div className='relative flex items-center justify-center bg-color-background-medium border border-color-background-dark h-28 overflow-clip'>
									<motion.div
										className='z-20 p-3 bg-color-background-light border border-color-background-dark rounded-full w-fit flex flex-col gap-2'
										initial={{ opacity: 0, y: 5 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.3, delay: 0.4 }}
									>
										<CircleUserRound strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
									</motion.div>

									<motion.div
										className='absolute z-10 h-[42px] w-[42px] bg-[radial-gradient(circle,_var(--color-background-dark)_0%,_transparent_100%)] border border-color-background-dark rounded-full animate-ping-slow'
										initial={{ opacity: 0, y: 5 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.3, delay: 0.4 }}
									/>

									<motion.div
										className='absolute z-10 w-[200px] h-[200px] border border-color-background-dark border-dashed rounded-full'
										initial={{ opacity: 0, rotate: 0 }}
										animate={{
											opacity: 1,
											rotate: 360,
										}}
										transition={{
											opacity: { delay: 0.6, duration: 1.5 },
											rotate: {
												duration: 240,
												repeat: Infinity,
												ease: 'linear',
											},
										}}
									>
										{Array(10)
											.fill(null)
											.map((_, index) => (
												<div
													key={index}
													style={{
														position: 'absolute',
														left: '50%',
														top: '50%',
														transform: `translate(-50%, -50%) rotate(${index * 36}deg) translateY(-100px)`,
													}}
												>
													<motion.div
														className='p-2 bg-color-background-light border border-color-background-dark rounded-full'
														initial={{ opacity: 0 }}
														animate={{ opacity: 1 }}
														transition={{ elay: 0.6, duration: 1.5 }}
													>
														<CircleDashed strokeWidth={1} className='h-4 w-4 text-color-background-placeholder' />
													</motion.div>
												</div>
											))}
									</motion.div>
								</div>

								<div className='flex flex-col gap-2 px-4 py-6'>
									<motion.div
										className='text-xl text-color-grayscale-dark font-normal'
										initial={{ y: '5px', opacity: 0 }}
										animate={{ y: 0, opacity: 1 }}
										transition={{ duration: 0.3, ease: 'easeInOut' }}
									>
										Join a Team
									</motion.div>

									<motion.div
										className='text-xs text-color-grayscale-medium font-normal leading-relaxed'
										initial={{ y: '5px', opacity: 0 }}
										animate={{ y: 0, opacity: 1 }}
										transition={{ duration: 0.3, delay: 0.1, ease: 'easeInOut' }}
									>
										To join an existing team, you'll need to be invited by a team member. Once invited, you'll receive an email with
										instructions to accept the invitation.
									</motion.div>

									<motion.div
										className='text-xs text-color-grayscale-medium font-normal leading-relaxed'
										initial={{ y: '5px', opacity: 0 }}
										animate={{ y: 0, opacity: 1 }}
										transition={{ duration: 0.3, delay: 0.2, ease: 'easeInOut' }}
									>
										Joining a team allows you to collaborate with colleagues, access shared properties, and utilize Dwelling's features
										together. If you haven't received an invite yet, reach out to your team administrator or colleagues to request one.
									</motion.div>
								</div>

								<div className='flex flex-row justify-end gap-2 p-4 border-t border-color-background-dark bg-color-background-light w-full mt-auto'>
									<DialogClose asChild>
										<Button variant='ghost' size='regular'>
											Close
										</Button>
									</DialogClose>
								</div>
							</DialogContent>
						</Dialog>
					</div>

					<img
						src={`/assets/branding/logo-icon-${_theme.theme === 'dark' ? 'dark' : 'medium'}.svg`}
						alt='Dwelling Logo'
						className='z-10 absolute -top-24 left-0 w-full pointer-events-none'
					/>
				</div>

				<div className='flex flex-col w-full'>
					{teams &&
						teams.map((team: z.infer<typeof Team>) => (
							<div
								className='flex flex-row gap-2 p-4 items-center hover:bg-color-background-medium border-b border-color-background-dark cursor-pointer transition-all'
								onClick={() => {
									navigate(`/${team.team_id}`);
								}}
							>
								<Avatar className='h-8 w-8 rounded-full'>
									<AvatarFallback>{team.name?.[0].toUpperCase() || '?'}</AvatarFallback>
								</Avatar>
								<div className='text-sm text-color-grayscale-medium font-normal ml-2'>{team.name}</div>

								{team.domain ? (
									<Badge variant='muted' size='regular' className='ml-auto'>
										Email access for @{team.domain}
									</Badge>
								) : (
									<Badge variant='muted' size='regular' className='ml-auto'>
										No email access
									</Badge>
								)}
								<Badge variant='indigo' size='regular'>
									{_.startCase(team.plan)}
								</Badge>
							</div>
						))}
				</div>
			</div>
		</motion.div>
	);
};
