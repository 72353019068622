import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { UseFormReturn, useForm } from 'react-hook-form';
import { AuthenticationService } from '@/services/authentication.service';
import { Button } from '@/styles/ui-components/button';
import { Input } from '@/styles/ui-components/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/styles/ui-components/form';
import { useNavigate } from 'react-router-dom';
import { AuthWrapper } from './_AuthWrapper';

const formSchema = z.object({
	email: z.string(),
	password: z.string(),
});

const EmailFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='email'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Email address</FormLabel>
				<FormControl>
					<Input autoFocus type='email' {...field} required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const PasswordFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='password'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Password</FormLabel>
				<FormControl>
					<Input {...field} type='password' required />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

interface SignInProps extends React.HTMLAttributes<HTMLDivElement> {}

export const SignIn = ({}: SignInProps) => {
	useEffect(() => {
		document.title = 'Dwelling • Sign In';
	}, []);

	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setLoading(true);
		setError('');
		const response = await AuthenticationService.signIn(values.email, values.password);
		if (response.status === 'success' || response.status === 'verify') {
			window.location.href = response.redirect as string;
		} else if (response.status === 'error') setError(response.message as string);
		if (response.status !== 'success') setLoading(false);
	};

	return (
		<AuthWrapper title='Dwelling.' subtitle='The financial stack for your properties.'>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-2'>
					<EmailFormField form={form} />
					<PasswordFormField form={form} />

					<Button variant='brand' className='mt-4' type='submit' loading={loading} disabled={loading}>
						Sign in
					</Button>

					{error && <div className='mt-2 text-xs text-red-600 leading-relaxed'>{error}</div>}
				</form>
			</Form>

			<div className='flex flex-row gap-4 w-full mt-2'>
				<Button variant='ghost' className='flex-1' onClick={() => navigate('/auth/sign-up')}>
					Create an account
				</Button>

				<Button variant='ghost' className='flex-1' onClick={() => navigate('/auth/forgot-password')}>
					Forgot password?
				</Button>
			</div>
		</AuthWrapper>
	);
};
