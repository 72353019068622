import useSWR, { SWRResponse } from 'swr';
import { store } from '@/lib/redux/store';
import { setUser as UserServiceSetState, clearState as UserServiceClearState } from '@/lib/redux/user.store';
import { BaseService } from './_base.service';
import { TeamService } from './team.service';
import { MapboxService } from './mapbox.service';

/**
 * @class AuthenticationService
 * Service for handling authentication. This service is used to sign in, sign up, and get the currently signed in user.
 */
export class AuthenticationService extends BaseService {
	private static getUser = async (token?: string): Promise<number> => {
		const accessToken = token || (await this.getAccessToken());
		const response = await fetch(this.getAuthUrl('get-user'), {
			method: 'POST',
			body: JSON.stringify({ access_token: accessToken }),
			headers: { 'Content-Type': 'application/json' },
		});

		if (response.ok) {
			const data = await response.json();
			store.dispatch(
				UserServiceSetState({
					id: data.UserAttributes.find((attribute: any) => attribute.Name === 'sub').Value,
					givenName: data.UserAttributes.find((attribute: any) => attribute.Name === 'given_name').Value,
					familyName: data.UserAttributes.find((attribute: any) => attribute.Name === 'family_name').Value,
					email: data.UserAttributes.find((attribute: any) => attribute.Name === 'email').Value,
					avatarUrl: null,
					accessToken: accessToken,
				})
			);
		}

		return response.status;
	};

	public static validateAccessToken = (): SWRResponse =>
		useSWR(this.getAuthUrl('validate-access-token'), async (url) => {
			if (this.getAccessToken() === null) return false;

			const response = await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ access_token: this.getAccessToken() }),
			});

			const result = await response.json();
			if (result.authorized) {
				await Promise.all([this.getUser(), TeamService.list(), MapboxService.getToken()]);
				return true;
			}

			this.clearAccessToken();
			return false;
		});

	public static signIn = async (
		email: string,
		password: string
	): Promise<{ status: 'success' | 'verify' | 'error'; redirect?: string; message?: string }> => {
		const response = await fetch(this.getAuthUrl('sign-in'), {
			method: 'POST',
			body: JSON.stringify({ email, password }),
			headers: { 'Content-Type': 'application/json' },
		});
		const data = await response.json();

		if (response.ok) {
			localStorage.setItem('access_token', data.AuthenticationResult.AccessToken);
			await this.getUser(data.AuthenticationResult.AccessToken);
			return { status: 'success', redirect: '/' };
		} else if (data.message.name === 'UserNotConfirmedException') {
			store.dispatch(UserServiceSetState({ email: email }));
			return { status: 'verify', redirect: '/auth/verify-account' };
		} else {
			return { status: 'error', message: data.message };
		}
	};

	public static signUp = async (
		givenName: string,
		familyName: string,
		email: string,
		password: string,
		confirmPassword: string
	): Promise<{ status: 'verify' | 'error'; redirect?: string; message?: string }> => {
		const response = await fetch(this.getAuthUrl('sign-up'), {
			method: 'POST',
			body: JSON.stringify({ given_name: givenName, family_name: familyName, email, password, confirm_password: confirmPassword }),
			headers: { 'Content-Type': 'application/json' },
		});
		const data = await response.json();

		if (response.ok) {
			store.dispatch(UserServiceSetState({ email: email }));
			return { status: 'verify', redirect: '/auth/verify-account' };
		} else {
			return { status: 'error', message: data.message };
		}
	};

	public static sendCode = async (email: string, password: string) => {
		await fetch(this.getAuthUrl('code/send'), {
			method: 'POST',
			body: JSON.stringify({ email, password }),
			headers: { 'Content-Type': 'application/json' },
		});
	};

	public static verifyCode = async (email: string, code: string) => {
		const response = await fetch(this.getAuthUrl('code/verify'), {
			method: 'POST',
			body: JSON.stringify({ email, code }),
			headers: { 'Content-Type': 'application/json' },
		});
		const data = await response.json();

		if (response.ok) {
			store.dispatch(UserServiceClearState());
			return { status: 'success' };
		} else {
			return { status: 'error', message: data.message };
		}
	};

	public static sendForgotPassword = async (email: string) => {
		return await fetch(this.getAuthUrl('forgot-password/send'), {
			method: 'POST',
			body: JSON.stringify({ email }),
			headers: { 'Content-Type': 'application/json' },
		});
	};

	public static verifyForgotPassword = async (
		email: string,
		code: string,
		password: string,
		confirm_password: string
	): Promise<{ status: 'success' | 'error'; message?: string }> => {
		const response = await fetch(this.getAuthUrl('forgot-password/verify'), {
			method: 'POST',
			body: JSON.stringify({ email, code, password, confirm_password }),
			headers: { 'Content-Type': 'application/json' },
		});
		const data = await response.json();

		if (response.ok) {
			store.dispatch(UserServiceClearState());
			return { status: 'success' };
		} else {
			return { status: 'error', message: data.message };
		}
	};
}
