import { useAppSelector } from '@/lib/hooks';
import { Property } from '@/lib/types';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Mapbox } from '@/app-modules/m-mapbox/m-mapbox';
import { z } from 'zod';

interface Overview_Props extends React.HTMLAttributes<HTMLDivElement> {}

export const Overview = ({}: Overview_Props) => {
	const { propertyId } = useParams();
	const _theme = useAppSelector((state) => state._theme);
	const properties = useAppSelector((state) => state.properties.properties);

	const [property, setProperty] = useState<z.infer<typeof Property> | undefined>(undefined);
	useEffect(() => {
		setProperty(properties?.find((p: z.infer<typeof Property>) => p.property_id === propertyId));
	}, [propertyId, properties]);

	const mapbox = useMemo(() => {
		console.log(_theme.theme);
		return (
			<Mapbox
				key={_theme.theme}
				theme={_theme.theme}
				latitude={property?.location.latitude || 0}
				longitude={property?.location.longitude || 0}
				className='h-[calc(100%+100px)] -mt-[50px]'
			/>
		);
	}, [property, _theme]);

	return (
		<div className='flex flex-col h-full w-full bg-color-background-light'>
			<div className='flex flex-row gap-2 w-full border-b border-color-background-dark'>
				<div className='flex flex-col gap-2 p-6'>
					<div className='text-2xl text-color-grayscale-dark font-normal items-center h-[32px]'>
						{property?.name &&
							property.name.split('').map((char, index) => (
								<motion.span
									key={`${property.name}-${index}`}
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ delay: index * 0.05, duration: 0.05 }}
									className='inline-block'
								>
									{char === ' ' ? '\u00A0' : char}
								</motion.span>
							))}
					</div>
					<div className='text-xs text-color-grayscale-medium font-normal leading-relaxed max-w-lg'>
						Manage your property's units and resident information. Update unit details, track occupancy, and maintain accurate records for each
						residence within your property.
					</div>
				</div>

				<div className='relative h-full w-full overflow-clip'>
					{property?.location.latitude !== undefined && property?.location.longitude !== undefined && (
						<motion.div
							key={property.property_id}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1.2, delay: 0.3 }}
							className='h-full w-full'
						>
							{mapbox}
						</motion.div>
					)}
					<div className='absolute z-50 top-0 left-0 w-[40%] h-full bg-gradient-to-l from-transparent to-color-background-light' />
				</div>
			</div>

			<div className='flex flex-row border-b border-color-background-dark'>
				<div className='py-2 pr-4 pl-6 text-xs text-color-grayscale-medium font-normal whitespace-nowrap border-r border-color-background-dark'>
					{property?.location.address}, {property?.location.city}, {property?.location.region} {property?.location.postcode},{' '}
					{property?.location.country}
				</div>
				<div className='py-2 px-4 text-xs text-color-grayscale-medium font-normal whitespace-nowrap border-r border-color-background-dark'>
					Built in {property?.year_built}
				</div>
			</div>
		</div>
	);
};
