import { Button } from '@/styles/ui-components/button';
import { Plus, Users2 } from 'lucide-react';
import React from 'react';

interface TeamMembers_Props extends React.HTMLAttributes<HTMLDivElement> {}

export const TeamMembers = ({}: TeamMembers_Props) => {
	return (
		<div className='flex flex-col gap-2 bg-color-background-medium'>
			<div className='flex flex-row items-center gap-2 p-2 border-b border-color-background-dark bg-color-background-light'>
				<Users2 strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light ml-2' />
				<div className='text-md text-color-grayscale-dark font-normal'>Members</div>

				<Button variant='ghost' size='regular' className='ml-auto'>
					<Plus strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
					Invite a colleague
				</Button>
			</div>
		</div>
	);
};
