import { Routes } from './routes/Routes.tsx';
import { useAppSelector } from './lib/hooks/useAppSelector.ts';
import { useEffect } from 'react';

export const App = () => {
	const _theme = useAppSelector((state) => state._theme);

	useEffect(() => {
		document.body.setAttribute('data-theme', localStorage.getItem('theme') ?? _theme.theme);
	}, [_theme.theme]);

	return <Routes />;
};
