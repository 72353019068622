import { useRef, useEffect, HTMLAttributes } from 'react';
import mapboxgl from 'mapbox-gl';
import { cn } from '@/lib/utils';
import { useAppSelector } from '@/lib/hooks';

interface Mapbox_Props extends HTMLAttributes<HTMLDivElement> {
	theme: 'light' | 'dark';
	latitude: number;
	longitude: number;
}

export const Mapbox = ({ theme, latitude, longitude, className }: Mapbox_Props) => {
	const mapRef = useRef<mapboxgl.Map | undefined>(undefined);
	const mapContainerRef = useRef<HTMLDivElement>(null);

	const token = useAppSelector((state) => state.mapbox.token);

	useEffect(() => {
		if (!token) return;

		mapboxgl.accessToken = token;
		mapRef.current = new mapboxgl.Map({
			container: 'map',
			center: [longitude, latitude],
			zoom: 10,
			pitch: 0,
			dragPan: false,
			dragRotate: false,
			scrollZoom: false,
			doubleClickZoom: false,
			keyboard: false,
			interactive: false,
		});

		mapRef.current?.on('style.load', () => {
			// Add a custom marker to the central location
			const el = document.createElement('div');
			el.className = 'custom-marker';
			el.style.width = '16px';
			el.style.height = '16px';
			el.style.backgroundColor = '#0076F6';
			el.style.border = '3px solid white';
			el.style.borderRadius = '50%';
			el.style.boxShadow = '0 0 0 8px rgba(0, 118, 246, 0.30)';

			// Add the marker to the map
			new mapboxgl.Marker(el).setLngLat([longitude, latitude]).addTo(mapRef.current!);

			mapRef.current?.setConfigProperty('basemap', 'lightPreset', theme === 'dark' ? 'night' : 'light');
			mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
			mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', false);
			mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
			mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);

			mapRef.current?.flyTo({ zoom: 11, duration: 10000 });
		});

		return () => {
			mapRef.current?.remove();
		};
	}, [token]);

	return <div id='map' ref={mapContainerRef} className={cn('h-full w-full', className)} />;
};
