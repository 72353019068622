import React, { Dispatch, KeyboardEvent, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@/styles/ui-components/table';
import { M_ManageTableHead } from './m-manage-tableHead';
import { M_ManageTableRow_Memoized } from './m-manage-tableRow';
import { Skeleton } from '@/styles/ui-components/skeleton';
import { cn } from '@/lib/utils';
import { CellPosition, handleKeyPositionChange } from './m-manage.utils';
import { z } from 'zod';
import { Property_UnitSchema } from '@/lib/types';
import dynamicIconImports from 'lucide-react/dynamicIconImports';
import { Unit } from '@/lib/types/unit.types';

interface M_ManageTable_Props extends React.HTMLAttributes<HTMLDivElement> {
	units: z.infer<typeof Unit>[] | undefined;
	unitSchema: z.infer<typeof Property_UnitSchema>[];
	setUnits: Dispatch<SetStateAction<z.infer<typeof Unit>[] | undefined>>;
}

export const M_ManageTable = ({ units, setUnits, unitSchema, className }: M_ManageTable_Props) => {
	const [_activeCell, setActiveCell] = useState<CellPosition>({ row: 0, column: 1 });

	const handleChange = useCallback((index: number, unit: any) => {
		setUnits((prevUnits) => {
			// setSort({ label: undefined, sort: 'asc' });
			if (!prevUnits) return [unit];
			const newUnits = [...prevUnits];
			newUnits[index] = unit;
			return newUnits;
		});
	}, []);

	const handleKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.metaKey && ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
				setActiveCell((prev) => handleKeyPositionChange(prev, units, e));
				return;
			}
		},
		[units]
	);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown as unknown as EventListener);
		return () => {
			window.removeEventListener('keydown', handleKeyDown as unknown as EventListener);
		};
	}, [handleKeyDown]);

	const tableHead = useMemo(
		() => (
			<TableHeader className='bg-color-background-light'>
				<TableRow className='border-b-0 text-xs text-color-grayscale-medium font-normal whitespace-nowrap'>
					{unitSchema
						.filter((schema: z.infer<typeof Property_UnitSchema>) => !schema.hidden)
						.sort((a: z.infer<typeof Property_UnitSchema>, b: z.infer<typeof Property_UnitSchema>) => a.position - b.position)
						.map((schema: z.infer<typeof Property_UnitSchema>) => (
							<M_ManageTableHead
								key={schema.label}
								icon={schema.icon as keyof typeof dynamicIconImports}
								label={schema.label}
								required={schema.required}
							/>
						))}
				</TableRow>
			</TableHeader>
		),
		[]
	);

	return (
		<div className={cn('flex-1 flex flex-col w-full h-fit bg-color-background-light', className)}>
			<Table className='w-full'>
				{tableHead}
				<TableBody className='bg-color-background-light'>
					{units === undefined &&
						Array.from({ length: 50 }).map((_, rowindex) => (
							<TableRow key={`skeleton-row-${rowindex}`} className='text-xs text-color-grayscale-medium font-normal whitespace-nowrap'>
								{Array.from({ length: 10 }).map((_, columnIndex) => (
									<TableCell
										key={`skeleton-row-${rowindex}-column-${columnIndex}`}
										className='border-r last:border-r-0 border-color-background-dark p-1'
									>
										<Skeleton className='w-full h-4' />
									</TableCell>
								))}
							</TableRow>
						))}

					{units?.map((unit: any, rowIndex: number) => (
						<M_ManageTableRow_Memoized
							key={`row-${rowIndex}`}
							unit={unit}
							unitSchema={unitSchema}
							onChange={(unit: any) => handleChange(rowIndex, unit)}
							// Manage keyboard driven movement throughout table
							rowIndex={rowIndex}
							onSelectColumn={(columnIndex: number) => setActiveCell({ row: rowIndex, column: columnIndex })}
						/>
					))}
				</TableBody>
			</Table>
		</div>
	);
};
