import { HTMLAttributes } from 'react';

interface AuthWrapperProps extends HTMLAttributes<HTMLDivElement> {
	title: string;
	subtitle?: string;
	description?: string;
}

export const AuthWrapper = ({ title, subtitle, description, children }: AuthWrapperProps) => {
	return (
		<div className='relative w-screen h-screen flex py-8 items-center justify-center'>
			<div className='flex-1 flex flex-col gap-2 justify-center max-w-[300px] w-full h-fit bg-color-background-light'>
				<img src='/assets/branding/logo-icon.svg' alt='Dwelling Logo' className='h-6 mr-auto mb-8' />
				<div className='text-2xl font-medium text-color-grayscale-dark'>{title}</div>
				{subtitle && <div className='text-2xl font-medium text-color-grayscale-medium'>{subtitle}</div>}
				{description && <div className='text-xs font-normal text-color-grayscale-medium leading-relaxed'>{description}</div>}

				<div className='flex flex-col gap-2 mt-4'>{children}</div>
			</div>
		</div>
	);
};
