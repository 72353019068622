import { Account } from '@/lib/types';
import { BaseService } from '@/services/_base.service';

/**
 * @class PlaidService
 * Service for interacting with plaid routes in the core-api
 */
export class PlaidService extends BaseService {
	/**
	 * Returns a link token for the Plaid Link widget
	 * @param teamId Team ID is used to validate the user's permissions
	 * @param propertyId Property ID is used as a userId for Plaid
	 * @returns Link token for Plaid Link widget
	 */
	public static createLinkToken = async (teamId: string, propertyId: string, redirectUri: string): Promise<string> => {
		const accessToken = await this.getAccessToken();
		const response = await fetch(this.getCoreUrl('plaid/create-link-token'), {
			method: 'POST',
			body: JSON.stringify({ team_id: teamId, property_id: propertyId, redirect_uri: redirectUri }),
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
		});

		const res = await response.json();
		return res.linkd.link_token;
	};

	public static exchangePublicToken = async (teamId: string, propertyId: string, publicToken: string): Promise<void> => {
		const accessToken = await this.getAccessToken();
		const response = await fetch(this.getCoreUrl('plaid/exchange-public-token'), {
			method: 'POST',
			body: JSON.stringify({ team_id: teamId, property_id: propertyId, public_token: publicToken }),
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
		});

		await response.json();
	};

	public static accountsGet = async (teamId: string, propertyId: string): Promise<Account[]> => {
		const accessToken = await this.getAccessToken();
		const response = await fetch(this.getCoreUrl('plaid/accounts-get'), {
			method: 'POST',
			body: JSON.stringify({ team_id: teamId, property_id: propertyId }),
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
		});

		const res = await response.json();

		const accounts = (res.accounts ?? [])
			.map((accountGroup: any[]) =>
				accountGroup.map(
					(account: any): Account => ({
						account_id: account.account_id,
						balances: {
							available: account.balances.available,
							current: account.balances.current,
							iso_currency_code: account.balances.iso_currency_code,
							last_updated_datetime: account.balances.last_updated_datetime,
						},
						mask: account.mask,
						name: account.name,
						official_name: account.official_name,
						subtype: account.subtype,
						type: account.type,
						verification_status: account.verification_status,
					})
				)
			)
			.flat();

		return accounts;
	};
}
