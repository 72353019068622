import { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { M_ManageTable } from '@/app-modules/m-manage/m-manage-table';
import { useAppSelector } from '@/lib/hooks';
import { Ellipsis, Eye, FolderInput, Lock } from 'lucide-react';
import { Button } from '@/styles/ui-components/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/styles/ui-components/dropdown-menu';
import { motion } from 'framer-motion';
import { M_ManageToolbar } from '@/app-modules/m-manage/m-manage.toolbar';
import { z } from 'zod';
import { M_ManageEditColumns } from '@/app-modules/m-manage/m-manage-editColumns';
import { Unit } from '@/lib/types/unit.types';
import { useParams } from 'react-router-dom';
import { Property } from '@/lib/types';

const areUnitsDifferent = (units: z.infer<typeof Unit>[], editingUnits: z.infer<typeof Unit>[]): boolean => {
	if (!units || !editingUnits) return false;
	if (units.length !== editingUnits.length) return true;

	return editingUnits.some((editingUnit, index) => {
		const originalUnit = units[index];
		return Object.keys(editingUnit).some((key) => editingUnit[key as keyof z.infer<typeof Unit>] !== originalUnit?.[key as keyof z.infer<typeof Unit>]);
	});
};

interface Manage_Props extends HTMLAttributes<HTMLDivElement> {}

export const Manage = ({}: Manage_Props) => {
	const { propertyId } = useParams();
	const units = useAppSelector((state) => state.unitsSlice.units);
	const properties = useAppSelector((state) => state.properties.properties);

	const [openEditColumns, setOpenEditColumns] = useState<boolean>(false);
	const [editingUnits, setEditingUnits] = useState<z.infer<typeof Unit>[] | undefined>(units);
	const [autosave, setAutosave] = useState<boolean>(false);
	const [renderScrollToTop, setRenderScrollToTop] = useState<boolean>(false);

	const renderSaveButtons = useMemo(() => areUnitsDifferent(units || [], editingUnits || []), [units, editingUnits]);

	const [property, setProperty] = useState<z.infer<typeof Property> | undefined>(undefined);
	useEffect(() => {
		setProperty(properties?.find((p: z.infer<typeof Property>) => p.property_id === propertyId));
	}, [propertyId, properties]);

	useEffect(() => {
		if (units) setEditingUnits(units);
	}, [units]);

	// const handleSave = async () => {
	// 	if (!editingUnits) return;
	// 	setLoadingSave(true);
	// 	const changedUnits = editingUnits.filter((editingUnit, index) => {
	// 		const originalUnit = unitsSlice.units?.[index];
	// 		return Object.keys(editingUnit).some((key) => editingUnit[key as keyof Unit] !== originalUnit?.[key as keyof Unit]);
	// 	});
	// 	await UnitService.bulkUpdate(teamId ?? '', changedUnits);
	// 	setLoadingSave(false);
	// };

	useEffect(() => {
		const element = document.getElementById('manage-scroll-container');
		const handleScroll = () => {
			if (!element) return;
			element.scrollTop < 100 ? setRenderScrollToTop(false) : setRenderScrollToTop(true);
		};

		handleScroll();
		element?.addEventListener('scroll', handleScroll);
		return () => element?.removeEventListener('scroll', handleScroll);
	}, [units]);

	return (
		<div id='manage-scroll-container' className='flex-1 flex flex-col h-full w-full bg-color-background-medium overflow-scroll'>
			{property !== undefined && <M_ManageEditColumns open={openEditColumns} setOpen={setOpenEditColumns} property={property} />}

			<div className='sticky left-0 flex flex-row gap-6 items-center border-b border-color-background-dark bg-color-background-light p-4'>
				<div className='flex flex-row items-center gap-2 text-2xl text-color-grayscale-dark font-normal'>{editingUnits?.length} Units</div>

				<div className='flex flex-row gap-2 h-fit ml-auto'>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant='ghost' size='icon'>
								<Ellipsis strokeWidth={1.5} className='w-4 h-4 text-color-grayscale-light' />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent className='mx-2'>
							<DropdownMenuItem className='flex flex-row items-center gap-2' onClick={() => setOpenEditColumns(true)}>
								<Eye strokeWidth={1.5} className='w-4 h-4 text-color-grayscale-light' />
								Hide / show columns
							</DropdownMenuItem>
							<DropdownMenuItem className='flex flex-row items-center gap-2'>
								<Lock strokeWidth={1.5} className='w-4 h-4 text-color-grayscale-light' />
								Lock unit column
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>

					<Button variant='outline' size='regular' className='ml-2 whitespace-nowrap'>
						<FolderInput strokeWidth={1.5} className='w-4 h-4 text-color-grayscale-light' />
						Import data
					</Button>
				</div>
			</div>

			{property?.unit_schema && (
				<M_ManageTable
					units={editingUnits}
					setUnits={setEditingUnits}
					unitSchema={property.unit_schema}
					className='mb-[68px] border-b border-color-background-dark bg-color-background-light'
				/>
			)}

			{editingUnits !== undefined && (
				<div className='z-50 fixed bottom-2 w-[100vw] -ml-[50px] flex flex-col items-center justify-center'>
					<motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 }}>
						<M_ManageToolbar
							autoSave={autosave}
							setAutoSave={(state: boolean) => setAutosave(state)}
							renderSave={renderSaveButtons}
							loadingSave={false}
							handleSave={() => console.log('save')}
							renderScrollToTop={renderScrollToTop}
							handleScrollToTop={() => {
								document.getElementById('manage-scroll-container')?.scrollTo({ top: 0, behavior: 'smooth' });
							}}
							className='w-fit bg-color-background-light border border-color-grayscale-light shadow-xl rounded-lg transition-all'
						/>
					</motion.div>
				</div>
			)}
		</div>
	);
};
