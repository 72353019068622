import { Property } from '@/lib/types';
import { store } from '@/lib/redux/store';
import { BaseService } from './_base.service';
import { setProperties } from '@/lib/redux/properties.store';
import { z } from 'zod';

import { toast } from 'sonner';

/**
 * @class PropertyService
 * Service for interacting with property routes in the core-api
 */
export class PropertyService extends BaseService {
	public static list = async (teamId: string): Promise<z.infer<typeof Property>[]> => {
		const accessToken = await this.getAccessToken();
		const response = await fetch(this.getCoreUrl('property/list'), {
			method: 'POST',
			body: JSON.stringify({ access_token: accessToken, team_id: teamId }),
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
		});

		try {
			const res = await response.json();
			const properties = res.properties.map((item: any) => Property.parse(item));
			store.dispatch(setProperties(properties));
			return properties;
		} catch (error) {
			console.error(error);
			toast('We could not fetch your properties.', {
				description: error instanceof Error ? error.message : 'Unknown error',
				cancel: {
					label: 'Close',
					onClick: () => console.log('Close'),
				},
			});
			return [];
		}
	};

	public static create = async (
		team_id: string,
		name: string,
		year_built: number | undefined,
		number_of_units: number | undefined,
		country: string,
		region: string,
		city: string,
		address: string,
		postcode: string
	): Promise<string | undefined> => {
		const accessToken = await this.getAccessToken();
		const response = await fetch(this.getCoreUrl('property/create'), {
			method: 'POST',
			body: JSON.stringify({
				access_token: accessToken,
				team_id,
				name,
				year_built,
				number_of_units,
				country,
				region,
				city,
				address,
				postcode,
			}),
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
		});

		if (!response.ok) return undefined;
		const data = await response.json();
		return data.property.property_id;
	};
}
