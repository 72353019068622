import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { UseFormReturn, useForm } from 'react-hook-form';
import { Loader2 } from 'lucide-react';
import { AuthenticationService } from '@/services/authentication.service';
import { Button } from '@/styles/ui-components/button';
import { Input } from '@/styles/ui-components/input';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/styles/ui-components/form';
import { useNavigate } from 'react-router-dom';
import { AuthWrapper } from './_AuthWrapper';
import { useAppSelector } from '@/lib/hooks';

const formSchema = z.object({
	code: z.string(),
	password: z.string().min(8, 'Password must be at least 8 characters long'),
	confirm_password: z.string().min(8, 'Password must be at least 8 characters long'),
});

const CodeFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='code'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Code</FormLabel>
				<FormControl>
					<Input type='password' {...field} required autoFocus />
				</FormControl>
				<FormMessage />
			</FormItem>
		)}
	/>
);

const PasswordFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='password'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Password</FormLabel>
				<FormControl>
					<Input type='password' {...field} required />
				</FormControl>
				<FormMessage className='text-error' />
			</FormItem>
		)}
	/>
);

const ConfirmPasswordFormField = ({ form }: { form: UseFormReturn<z.infer<typeof formSchema>> }) => (
	<FormField
		control={form.control}
		name='confirm_password'
		render={({ field }) => (
			<FormItem>
				<FormLabel>Confirm password</FormLabel>
				<FormControl>
					<Input type='password' {...field} required />
				</FormControl>
				<FormMessage className='text-error' />
			</FormItem>
		)}
	/>
);

interface VerifyPasswordProps extends React.HTMLAttributes<HTMLDivElement> {}

export const VerifyPassword = ({}: VerifyPasswordProps) => {
	useEffect(() => {
		document.title = 'Dwelling • Verify Password';
	}, []);

	const navigate = useNavigate();
	const user = useAppSelector((state) => state.user);

	const [loading, setLoading] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	useEffect(() => {
		if (!success && !user.email) navigate('/auth/sign-in');
	}, [success]);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			code: '',
			password: '',
			confirm_password: '',
		},
	});

	const onSubmit = async (values: z.infer<typeof formSchema>) => {
		setLoading(true);
		const response = await AuthenticationService.verifyForgotPassword(user.email || '', values.code, values.password, values.confirm_password);
		if (response.status === 'success') setSuccess(true);
		else if (response.status === 'error') setError(response.message || '');
		setLoading(false);
	};

	return (
		<AuthWrapper
			title={!success ? 'Reset your password.' : 'Password updated.'}
			description={
				!success
					? `A reset password code has been sent to ${user.email}. Enter the code and your new password below.`
					: `Your password has been updated. You can now sign in to your account using this new password.`
			}
		>
			{!success && (
				<>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-2'>
							<CodeFormField form={form} />
							<PasswordFormField form={form} />
							<ConfirmPasswordFormField form={form} />

							<Button variant='brand' className='w-full flex flex-row gap-2 items-center justify-center mt-4' type='submit' disabled={loading}>
								{loading && <Loader2 className='w-4 h-4 animate-spin' />}
								{!loading && 'Reset password'}
							</Button>

							{error && <div className='mt-2 text-xs text-red-700 leading-relaxed'>{error}</div>}
						</form>
					</Form>

					<div className='flex flex-row gap-4 w-full mt-2'>
						<Button variant='ghost' className='flex-1' onClick={() => navigate('/auth/sign-in')}>
							Back to sign in
						</Button>
					</div>
				</>
			)}

			{success && (
				<div className='flex flex-row gap-4 w-full mt-2'>
					<Button variant='brand' className='flex-1' onClick={() => navigate('/auth/sign-in')}>
						Back to sign in
					</Button>
				</div>
			)}
		</AuthWrapper>
	);
};
