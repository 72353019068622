import { Dispatch, HTMLAttributes, SetStateAction } from 'react';
import { z } from 'zod';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/styles/ui-components/dialog';
import _ from 'lodash';
import { Switch } from '@/styles/ui-components/switch';
import { ChevronDown, Columns, GripVertical, Trash } from 'lucide-react';
import { Button } from '@/styles/ui-components/button';
import { Input } from '@/styles/ui-components/input';
import { Badge } from '@/styles/ui-components/badge';
import { Property } from '@/lib/types';
import { Popover, PopoverContent, PopoverTrigger } from '@/styles/ui-components/popover';

interface M_ManageEditColumns_Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	property: z.infer<typeof Property>;
}

export const M_ManageEditColumns = ({ open, setOpen, property }: M_ManageEditColumns_Props) => {
	console.log(property);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className='max-h-[calc(100vh-50px)] overflow-y-scroll'>
				<DialogHeader className='z-50 sticky top-0 bg-color-background-light border-b border-color-background-dark'>
					<DialogTitle>Manage Table Columns</DialogTitle>
					<DialogDescription>
						Manage which columns are visible in the table to tailor your data view. Remember, your column preferences will be saved, ensuring a
						consistent view each time you return to the table.
					</DialogDescription>

					<div className='flex flex-row items-center gap-2 mt-2'>
						<Input placeholder='Search columns' className='w-full' />
						<Button variant='outline' size='small' className='whitespace-nowrap'>
							<Columns strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
							Add a column
						</Button>
					</div>
				</DialogHeader>

				<div className='flex flex-col'>
					{property.unit_schema.map((obj: z.infer<typeof Property>['unit_schema'][0]) => {
						return (
							<div
								key={obj.label}
								className='flex flex-row items-center gap-2 px-4 py-1 border-t first:border-t-0 border-color-background-dark hover:bg-color-background-medium transition-all'
							>
								{Array.isArray(obj.type) ? (
									<Popover>
										<PopoverTrigger className='group'>
											<Badge variant='muted' className='text-xs flex flex-row items-center gap-1'>
												Multi Select
												<ChevronDown
													strokeWidth={1.5}
													className='h-4 w-4 text-color-grayscale-light transition-transform group-data-[state=open]:rotate-180'
												/>
											</Badge>
										</PopoverTrigger>
										<PopoverContent className='flex flex-col overflow-clip'>
											{obj.type.map((type: string) => (
												<div className='flex flex-row items-center gap-2 px-3 py-2 text-xs text-color-grayscale-medium font-normal border-b last:border-none border-color-background-dark hover:bg-color-background-medium transition-all'>
													<div className='h-2 w-2 rounded-full bg-color-background-dark' />
													{type}
												</div>
											))}
										</PopoverContent>
									</Popover>
								) : (
									<Badge variant='muted' className='text-xs'>
										{_.startCase(obj.type)}
									</Badge>
								)}
								<div className='text-xs text-color-grayscale-medium font-normal mr-auto'>{_.startCase(obj.label)}</div>

								<div className='flex flex-row gap-2 items-center'>
									{!obj.required && (
										<Button variant='ghost' size='small'>
											<Trash strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
										</Button>
									)}

									<Switch checked={!obj.hidden} />

									<Button variant='ghost' size='small' className='cursor-grab'>
										<GripVertical strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
									</Button>
								</div>
							</div>
						);
					})}
				</div>

				<DialogFooter className='sticky bottom-0'>
					<Button variant='outline' size='regular' onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button variant='success' size='regular'>
						Save
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
