import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from '@/lib/redux/user.store';
import { teamsSlice } from '@/lib/redux/teams.store';
import { propertiesSlice } from '@/lib/redux/properties.store';
import { unitsSlice } from '@/lib/redux/units.store';
import { mapboxSlice } from '@/lib/redux/mapbox.store';
import { themeSlice } from './_theme.store';

export const store = configureStore({
	reducer: {
		_theme: themeSlice.reducer,

		user: userSlice.reducer,
		teams: teamsSlice.reducer,
		properties: propertiesSlice.reducer,
		unitsSlice: unitsSlice.reducer,

		// State for handling Mapbox functionality (token retrieval, etc.)
		mapbox: mapboxSlice.reducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
