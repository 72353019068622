import { createSlice } from '@reduxjs/toolkit';

interface ThemeState {
	theme: 'light' | 'dark';
}

const initialState: ThemeState = {
	theme: (localStorage.getItem('theme') as 'light' | 'dark') ?? 'light',
};

export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		setTheme: (state, action) => {
			state.theme = action.payload ?? undefined;
			localStorage.setItem('theme', state.theme);
		},
		clearTheme: (state) => {
			state.theme = initialState.theme;
		},
	},
});

export const { setTheme, clearTheme } = themeSlice.actions;
