import { HTMLAttributes } from 'react';
import { Building } from 'lucide-react';
import { motion } from 'framer-motion';
import { Separator } from '@/styles/ui-components/separator';

interface M_OnboardingProperty_Review_Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
	name: string;
	country: string;
	state: string;
	city: string;
	address: string;
	zip: string;
	yearBuilt: number | undefined;
	numberOfUnits: number | undefined;
}

export const M_OnboardingProperty_Review = ({ name, country, state, city, address, zip, yearBuilt, numberOfUnits }: M_OnboardingProperty_Review_Props) => {
	return (
		<div className='relative overflow-clip flex flex-col'>
			<div className='relative flex items-center justify-center bg-color-background-medium border border-color-background-dark h-28 overflow-clip'>
				<motion.div
					className='z-20 p-3 bg-color-background-light border border-color-background-dark rounded-full w-fit flex flex-col gap-2'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				>
					<Building strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light' />
				</motion.div>

				<motion.div
					className='absolute z-10 h-[42px] w-[42px] bg-[radial-gradient(circle,_var(--color-background-dark)_0%,_transparent_100%)] border border-color-background-dark rounded-full animate-ping-slow'
					initial={{ opacity: 0, y: 5 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.4 }}
				/>
			</div>

			<div className='flex flex-col gap-2 px-4 py-6'>
				<motion.div
					className='text-xl text-color-grayscale-dark font-normal'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}
				>
					Review your Property
				</motion.div>

				<motion.div
					className='text-xs text-color-grayscale-light font-normal leading-relaxed'
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.1, ease: 'easeInOut' }}
				>
					Please review your property details below to ensure everything is correct. If you need to make any changes, you can easily do so later in
					the property settings.
				</motion.div>

				<motion.div
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.2, ease: 'easeInOut' }}
					className='text-sm text-color-grayscale-dark font-normal overflow-ellipsis line-clamp-1 mt-4'
				>
					{name}
				</motion.div>

				<motion.div
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.3, ease: 'easeInOut' }}
					className='flex flex-col gap-1'
				>
					<span className='text-xs text-color-grayscale-medium font-normal overflow-ellipsis line-clamp-1 leading-relaxed'>
						{address}, {zip}
					</span>
					<span className='text-xs text-color-grayscale-medium font-normal overflow-ellipsis line-clamp-1 leading-relaxed'>
						{city}, {state}, {country}
					</span>
					<span className='text-xs text-color-grayscale-medium font-normal leading-relaxed'>{numberOfUnits} units</span>
					<span className='text-xs text-color-grayscale-medium font-normal leading-relaxed'>Built in {yearBuilt}</span>
				</motion.div>

				<Separator orientation='horizontal' className='w-full my-2' />

				<motion.div
					initial={{ y: '5px', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.4, ease: 'easeInOut' }}
					className='text-xs text-color-grayscale-light font-normal leading-relaxed'
				>
					This building will not be included in your monthly billing statement until the units are configured. We only bill for active units.
				</motion.div>
			</div>
		</div>
	);
};
