import React, { useEffect, useState } from 'react';
import { M_Plaid_Link } from '@/app-modules/m-plaid/m-plaid-link';
import { MoreHorizontal, Trash } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { PlaidService } from '@/services/plaid.service';
import { Account } from '@/lib/types';
import { Badge } from '@/styles/ui-components/badge';
import _ from 'lodash';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/styles/ui-components/dropdown-menu';
import { Button } from '@/styles/ui-components/button';

interface Payments_Props extends React.HTMLAttributes<HTMLDivElement> {}

export const Payments = ({}: Payments_Props) => {
	const { teamId, propertyId } = useParams();
	const [accounts, setAccounts] = useState<Account[]>([]);

	useEffect(() => {
		if (teamId && propertyId) PlaidService.accountsGet(teamId, propertyId).then((accounts: Account[]) => setAccounts(accounts));
	}, [teamId, propertyId]);

	return (
		<div className='flex flex-row justify-between h-full bg-color-background-light'>
			<div className='flex flex-col gap-2 p-6 w-full border-b border-color-background-dark'>
				<div className='text-2xl text-color-grayscale-dark font-normal flex flex-row gap-2 items-center'>Payment Plans</div>
				<div className='text-xs text-color-grayscale-medium font-normal leading-relaxed max-w-lg'>
					Manage payment plans for residents. Create recurring monthly payments to collect condo fees, or one-time payments for levies, fines, and
					more.
				</div>
			</div>

			<div className='flex flex-col border-l border-color-background-dark min-w-[400px] max-w-[400px]'>
				<div className='flex flex-col bg-color-background-light'>
					<div className='flex flex-col gap-1 p-4 border-b border-color-background-dark'>
						<div className='text-lg text-color-grayscale-dark font-normal'>Connected Accounts</div>
						<div className='text-xs text-color-grayscale-medium font-normal leading-relaxed max-w-xs'>
							We use Plaid to securely connect your property's financial accounts. Before configuring payment plans with your residents, connect
							your building's financial accounts.
						</div>

						<M_Plaid_Link />
					</div>

					{accounts.map((account: Account) => (
						<div className='flex flex-col border-b border-color-background-dark p-4 text-xs text-color-grayscale-medium font-normal'>
							<div className='flex flex-row gap-1 items-start justify-between'>
								<div className='flex flex-col gap-1'>
									<div className='text-sm text-color-grayscale-dark'>{account.name}</div>
									<div className='text-xs text-color-grayscale-light'>{account.official_name}</div>
								</div>

								<div className='flex flex-row gap-2 items-center'>
									<Badge variant='outline' size='regular'>
										{_.upperFirst(account.subtype)}
									</Badge>

									<DropdownMenu>
										<DropdownMenuTrigger className='focus:outline-none'>
											<Badge variant='outline' size='small' className='cursor-pointer'>
												<MoreHorizontal strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-medium' />
											</Badge>
										</DropdownMenuTrigger>
										<DropdownMenuContent className='m-1 min-w-[200px]'>
											<DropdownMenuItem asChild>
												<Button variant='ghost' size='regular' className='w-full'>
													Remove account
													<Trash strokeWidth={1.5} className='h-4 w-4 text-color-grayscale-light ml-auto' />
												</Button>
											</DropdownMenuItem>
										</DropdownMenuContent>
									</DropdownMenu>
								</div>
							</div>

							<div className='flex flex-row gap-2 mt-3 items-center'>
								<div className='text-sm text-color-grayscale-medium whitespace-nowrap mr-auto'>
									{account.balances.available} {account.balances.iso_currency_code}
								</div>
								<div className='text-sm text-color-background-placeholder whitespace-nowrap'>
									{account.balances.current - account.balances.available} {account.balances.iso_currency_code} Unavailable
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
